import { Link, useLocation } from "react-router-dom";
import Winylo from "@winylo/winylo-react-component";

import style from "./SidePannel.module.css";

import classNames from "classnames";

import { useState } from "react";
import { UserContextType, useUser } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ROLES } from "../../api/_type";
import { useMutation } from "react-query";
import api from "../../api/api";
import icons from "../../utils/icons";
import { faBug, IconDefinition } from "@fortawesome/free-solid-svg-icons";

export default function SidePannel({ children }: { children: React.ReactElement }) {
  const [user] = useUser() as UserContextType;
  const location = useLocation();

  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [isBugOpen, setIsBugOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [bugFormValue, setBugFormValue] = useState<string>("");

  const { mutate: sendBug } = useMutation(api.bugs.sendBug, {
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      setBugFormValue("");
      setIsBugOpen(false);
      setIsLoading(false);
    },
  });

  function MenuRow({ path, icon, text, role, id }: { path: string; icon: IconDefinition; text: string; role?: ROLES; id?: string }) {
    return role === undefined || user?.roles.includes(role) ? (
      <Link
        id={id}
        to={path}
        className={classNames(
          {
            [style.selectedRow]: location.pathname === path,
          },
          style.menuRow
        )}
      >
        <span className={style.iconContainer}>
          <FontAwesomeIcon icon={icon} className={style.icon} />{" "}
        </span>
        <span className={style.menuRowText}>{text}</span>
      </Link>
    ) : null;
  }

  function handleCancelBugForm() {
    setBugFormValue("");
    setIsBugOpen(false);
  }

  function handleSubmitBugForm() {
    sendBug({ userId: user?.id, message: bugFormValue });
  }

  return (
    <div className={style.widthContainer}>
      <div className={classNames(style.left, { [style.opened]: isMenuOpen })}>
        {isMenuOpen ? (
          <img
            src={process.env.PUBLIC_URL + "/img/burger-close.svg"}
            alt="fermer"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/img/burger-open.svg"}
            alt="ouvrir"
            className={style.openCloseIcon}
            onClick={() => setIsMenuOpen((before) => !before)}
          />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <MenuRow path="/" icon={icons.v5_faHome} text="Accueil" />
          <MenuRow path="/folders" icon={icons.v5_faClipboard} text="Galerie" id="manage-medias" />
        </div>
        <div className={style.bug} onClick={() => setIsBugOpen(true)}>
          <MenuRow path="" icon={faBug} text="Remonter un bug" />
        </div>
      </div>
      <div className={classNames(style.right, { [style.opened]: isMenuOpen })}>{children}</div>

      <Winylo.Modal title="Remonter un bug" isOpen={isBugOpen} onClose={() => setIsBugOpen(false)}>
        <div className={style.bugFormContainer}>
          <Winylo.Textarea label="Description du problème" value={bugFormValue} onChange={(e) => setBugFormValue(e.target.value)}></Winylo.Textarea>
          <div style={{ display: "flex", marginTop: "10px", marginBottom: 0, justifyContent: "space-between" }}>
            <Winylo.Button variant="gray" fullWidth={false} format="square" onClick={() => handleCancelBugForm()}>
              Annuler
            </Winylo.Button>
            <Winylo.Button loading={isLoading} format="square" fullWidth={false} onClick={() => handleSubmitBugForm()} style={{ marginLeft: "auto" }}>
              Envoyer
            </Winylo.Button>
          </div>
        </div>
      </Winylo.Modal>
    </div>
  );
}
