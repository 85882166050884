import { useState, useContext, createContext } from "react";
import { MediaV2 } from "../api/_type";

export type MediasContextType = [MediaV2[] | undefined, React.Dispatch<React.SetStateAction<MediaV2[] | undefined>>];

const MediasContext = createContext<MediasContextType | undefined>(undefined);

export function MediasContextProvider({ children }: { children: React.ReactElement }) {
    const [medias, setMedias] = useState<MediaV2[] | undefined>(undefined);

    return <MediasContext.Provider value={[medias, setMedias]}>{children}</MediasContext.Provider>;
}

export function useMedias() {
    return useContext(MediasContext);
}
