import React from "react";
import classNames from "classnames";
import style from "./Checkbox.module.css";

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    label?: React.ReactNode;
    click?: React.MouseEventHandler<HTMLLabelElement>;
    invalid?: boolean;
    labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
}

export default function Checkbox(props: Props) {
    return (
        <label
            {...props.labelProps}
            className={classNames(
                style.checkbox,
                {
                    [style.invalid]: props.invalid,
                },
                props.labelProps?.className
            )}
            onClick={props.click}
        >
            <input type="checkbox" {...props} />
            {props.label}
        </label>
    );
}
