import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";
import { OnboradingEvents } from "./events.onboarding";

export function createAddMediasMobileSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Terminer";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Ajouter un média</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 6;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      attachTo: { element: "#modal-download-app", on: "right" },
      advanceOn: { selector: "#modal-download-app", event: "click" },
      beforeShowPromise: function () {
        document.dispatchEvent(new CustomEvent(OnboradingEvents.OPEN_DOWNLOAD_APP_MODAL));
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            document.dispatchEvent(new CustomEvent(OnboradingEvents.CLOSE_DOWNLOAD_APP_MODAL));
            this.next();
          },
        },
      ],
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
       Scannez le <span class="important-text">QR Code</span> avec votre téléphone<br/>
       Pour télécharger l'application.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
          document.dispatchEvent(new CustomEvent(OnboradingEvents.CLOSE_DOWNLOAD_APP_MODAL));
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      classes: "add-media-mobile-tour",
      buttons: [
        {
          classes: "shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new CustomEvent(OnboradingEvents.OPEN_DOWNLOAD_APP_MODAL));
            this.back();
          },
        },
        {
          classes: "shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <p>Sélectionnez le dossier dans lequel vous voulez ajouter une photo ou une vidéo.</p>
        <div style="text-align: center;">
       <img src="${MY_DOMAIN}/onboarding/step-1.png" style="height: 40rem;"/>
       </div>
       `,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      classes: "add-media-mobile-tour",
      buttons: [
        {
          classes: "shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <p style="display: flex; align-items: center;">Cliquez sur l'icône appareil photo. <img src="${MY_DOMAIN}/onboarding/icon-camera.png" height="32" width="32" style="margin-left: 10px;"/></p>
        <div style="text-align: center;">
       <img src="${MY_DOMAIN}/onboarding/step-2.png" style="height: 40rem;"/>
       </div>
       `,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      classes: "add-media-mobile-tour",
      buttons: [
        {
          classes: "shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <p>Prenez votre photo ou votre vidéo.</p>
        <p>Vous pouvez aussi importer une photo ou une vidéo depuis votre galerie.</p>
        <div style="text-align: center;">
       <img src="${MY_DOMAIN}/onboarding/step-3.png" style="height: 40rem;"/>
       </div>
       `,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      classes: "add-media-mobile-tour",
      buttons: [
        {
          classes: "shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <p style="display: flex; align-items: center;">Vous pouvez annoter les photos en cliquant sur l'icône crayon <img src="${MY_DOMAIN}/onboarding/pen-solid.svg" height="32" width="32" style="margin-left: 10px;"/></p>
        <p style="display: flex; align-items: center;">Vous pouvez ajouter plusieurs médias en même temps en cliquant sur l'icône <img src="${MY_DOMAIN}/onboarding/icon-plus.png" height="32" width="32" style="margin-left: 10px;"/></p>
        <p style="display: flex; align-items: center;">Cliquez sur l'icône <img src="${MY_DOMAIN}/onboarding/icon-arrow.png" height="32" width="32" style="margin: 0 10px;"/> pour passer à l'étape suivante.</p>
        <div style="text-align: center;">
       <img src="${MY_DOMAIN}/onboarding/step-4.png" style="height: 40rem;"/>
       </div>
       `,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      classes: "add-media-mobile-tour",
      buttons: [
        {
          classes: "shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        <p>Ici, donnez un <span class="important-text">nom</span> et une <span class="important-text">description</span> à votre média.</p>
        <p style="line-height: 32px;">Cliquez sur l'icône <img src="${MY_DOMAIN}/onboarding/icon-qr.png" height="32" width="32" style="margin: 0 10px; margin-bottom: -12px;"/> pour scanner un QR Code ou un code barre, <br/>
        qui servira de nom pour le média à ajouter.
        .</p>
        <p style="display: flex; align-items: center;">Cliquez sur l'icône <img src="${MY_DOMAIN}/onboarding/icon-check.png" height="32" width="32" style="margin: 0 10px;"/> valider et ajouter le média.</p>
        <div style="text-align: center;">
       <img src="${MY_DOMAIN}/onboarding/step-5.png" style="height: 40rem;"/>
       </div>
       `,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      buttons: [
        {
          classes: "shepherd-button-complete",
          text: completeText,
          action() {
            this.complete();
            navigate("/");
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
      },
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel !<br/><br/>
        Vous pouvez maintenant fermer cet onglet.`,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  ];
}
