import { checkStatus, URL_HOST } from "./api";
import { PageItem, Patchnote } from "./_type";

import qs from "qs";

export interface PatchnotePostParams extends Partial<Omit<Patchnote, "id" | "application" | "scheduledAt">> {
  application: number;
}

const patchnotes = {
  getPatchnotes: ({ page }: { page: number }): Promise<PageItem<Patchnote>> => {
    return fetch(`${URL_HOST}/api/patchnotes?${qs.stringify({ page })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (localStorage.getItem("jwt") || ""),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default patchnotes;
