import classNames from "classnames";
import React from "react";
import ReactModal from "react-modal";
import { MY_DOMAIN } from "../../utils/utils";

import style from "../css/Loader.module.css";

import { ReactComponent as Xmark } from "../svg/xmark-solid.svg";

interface Props {
  isLoading: boolean;
  className?: string;
  children?: React.ReactNode;
  childrenContainerStyle?: React.CSSProperties;
}

export default function Loader(props: Props) {
  return (
    <>
      {props.isLoading && (
        <div className={props.className}>
          <div
            className={style.previewImage}
            style={{
              backgroundImage: `url('${MY_DOMAIN}/loading.png')`,
            }}
          />
          <div style={props.childrenContainerStyle}>{props.children}</div>
        </div>
      )}
    </>
  );
}
