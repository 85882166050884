import { useEffect, useState } from "react";
import api from "../../api/api";

import { UserContextType, useUser } from "../../context/UserContext";
import {
  HasCheckLoginContextType,
  useHasCheckLogin,
} from "../../context/HasCheckLoginContext";
import { JWT_PERIOD } from "../../utils/constantes";
import { parseQuery } from "../../utils/utils";
import { useNavigate } from "react-router-dom";

export default function ReturnLogin() {
  const navigate = useNavigate();
  const [isError, setIsError] = useState("");
  const [, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  useEffect(() => {
    const hash = parseQuery();

    if (hash.get("code") === null) {
      setIsError("Vous devez rentrer un code sur cette route");
    } else {
      api.loginApi
        .exchangeCode({ code: hash.get("code") as string })
        .then((data) => {
          localStorage.setItem("jwt", data.jwt);

          api.loginApi
            .getMe()
            .then((data) => {
              localStorage.setItem("jwt", data.jwt);

              setHasCheckLogin(true);
              setUser(data.user);

              setTimeout(refetchMe, JWT_PERIOD);

              navigate(hash.get("redirect_page") as string);
            })
            .catch((err) => {
              console.log(err);
              setIsError(JSON.parse(err.message).message);
              setUser(undefined);
            });
        })
        .catch((err) => {
          setIsError("Le code que vous avez rentré est invalide");
        });
    }

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          console.log("%cAcualisation du JWT", "color: blue");

          localStorage.setItem("jwt", data.jwt);

          setHasCheckLogin(true);
          setUser(data.user);

          setTimeout(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setIsError(JSON.parse(err.message).message);
          setUser(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isError !== "" ? <div>{isError}</div> : <></>;
}
