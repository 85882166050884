import React from "react";

import { useNavigate, useLocation } from "react-router";
import { ROLES } from "../api/_type";
import { HasCheckLoginContextType, useHasCheckLogin } from "../context/HasCheckLoginContext";
import { UserContextType, useUser } from "../context/UserContext";

interface Props {
    children: any;
    roles?: ROLES[];
}

export default function NeedLogin({ children, roles }: Props): React.ReactElement {
    const navigate = useNavigate();
    const location = useLocation();

    const [user] = useUser() as UserContextType;
    const [hasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

    if (hasCheckLogin) {
        if (user !== undefined) {
            if (roles === undefined) {
                return children;
            } else {
                for (const role of roles) {
                    if (user.roles.includes(role)) {
                        return children;
                    }
                }
                navigate("/");
                return <></>;
            }
        } else {
            navigate("/login#redirect=" + location.pathname);
            return <></>;
        }
    } else {
        return <></>;
    }
}
