import { Range } from "react-range";
import Compressor from "compressorjs";
import Select from "react-select";
import Winylo from "@winylo/winylo-react-component";
import { getBase64, MY_DOMAIN, useDebounce } from "../../utils/utils";
import { Category, VIDEO_QUALITY } from "../../api/_type";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useFormik } from "formik";
import api from "../../api/api";
import * as Yup from "yup";

import style from "./CategoryModal.module.css";
import Switch from "../../winylo/components/Switch";

interface Props {
  category?: Category;
  parentCategory?: Category;
  open: boolean;
  onClose: () => void;
}

export default function GeneralForm(props: Props) {
  const [previewImage, setPreviewImage] = useState<File | null>(null);
  const [previewBase64, setPreviewBase64] = useState<string | null>(null);
  const [currentSize, setCurrentSize] = useState<number>(0);

  const [compression, setCompression] = useDebounce<any>(
    null,
    (compression) => {
      handleCompressionChange(compression);
    },
    500,
    (compression) => formik.setFieldValue("photoCompression", compression)
  );

  const create = props.category === undefined;

  const queryClient = useQueryClient();

  const { mutate: createCategory } = useMutation(api.categories.createCategory, {
    onSuccess: (result) => {
      queryClient.invalidateQueries("categories");
      queryClient.invalidateQueries("filtered_categories");
      onClose();
    },
  });

  const { mutate: updateCategory } = useMutation(api.categories.updateCategory, {
    onSuccess: (result) => {
      queryClient.setQueryData<Category[] | undefined>("categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        for (let i = 0; i < old?.length; i++) {
          if (old[i].id === result.id) {
            old[i] = result;
            old.sort((a, b) => a.label.localeCompare(b.label));
          } else {
            let oldCatSubs = old[i].subCategories;
            if (oldCatSubs) {
              for (let j = 0; j < oldCatSubs.length; j++) {
                if (oldCatSubs[j].id === result.id) {
                  oldCatSubs[j] = result;
                  oldCatSubs.sort((a, b) => a.label.localeCompare(b.label));
                  break;
                }
              }
            }
          }
        }
        return old;
      });

      queryClient.setQueryData<Category[] | undefined>("filtered_categories", (old: Category[] | undefined) => {
        if (!old) return undefined;
        return old.map((category: Category) => (category.id === result.id ? result : category));
      });
      onClose();
    },
  });

  const qualityOptions = [
    { value: VIDEO_QUALITY.LOW, label: "Basse (480p)" },
    { value: VIDEO_QUALITY.MEDIUM, label: "Moyenne (720p)" },
    { value: VIDEO_QUALITY.HIGH, label: "Haute (1080p)" },
  ];

  const initialValues = {
    label: "",
    photoCompression: [0],
    quality: qualityOptions[2],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      label: Yup.string().required("Le nom du dossier ne peut pas être vide."),
    }),
    onSubmit: (values) => {
      let body = {
        superId: props.parentCategory?.id,
        label: values.label,
        photoCompression: values.photoCompression[0],
        videoQuality: values.quality.value,
      };

      if (create) {
        createCategory(body);
      } else {
        if (props.category?.id !== undefined)
          updateCategory({
            id: props.category?.id,
            body: props.category.isLocked ? { photoCompression: body.photoCompression, videoQuality: body.videoQuality } : body,
          });
      }
    },
  });

  function onClose() {
    formik.resetForm();
    if (props.onClose !== undefined) props.onClose();
  }

  function handleCompressionChange(values: number[]) {
    if (previewImage) {
      new Compressor(previewImage, {
        quality: 1 - values[0] / 100,

        success(result: any) {
          getBase64(result).then((base64) => {
            setPreviewBase64(base64);
            setCurrentSize(result.size);
          });
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  }

  useEffect(() => {
    if (props.open) {
      props.category?.photoCompression ? handleCompressionChange([props.category.photoCompression]) : handleCompressionChange([0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  useEffect(() => {
    fetch(`${MY_DOMAIN}/compressionPreview.jpg`).then((response) => {
      response.blob().then((data) => {
        let metadata = {
          type: "image/jpeg",
        };
        let file = new File([data], "test.jpg", metadata);
        setPreviewImage(file);
      });
    });
  }, []);

  useEffect(() => {
    previewImage && handleCompressionChange([props.category ? props.category.photoCompression : 0]);
  }, [previewImage]);

  useEffect(() => {
    if (props.category) {
      formik.resetForm({
        values: {
          label: props.category.label,
          photoCompression: [props.category.photoCompression || 0],
          quality: qualityOptions.find((qualityOption) => qualityOption.value === props.category?.videoQuality) || qualityOptions[2],
        },
      });
    } else {
      formik.resetForm({
        values: initialValues,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.category]);

  return (
    <form className={style.categoryModal} onSubmit={formik.handleSubmit}>
      <span id="folder-modal">
        <Winylo.Input
          style={{ lineHeight: "2rem" }}
          autoComplete="off"
          id="label"
          name="label"
          variant={formik.touched.label && formik.errors.label ? "invalid" : "normal"}
          message={formik.touched.label && formik.errors.label ? formik.errors.label : undefined}
          label="Nom du dossier*"
          placeholder="Exemple: Photos atelier"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.label}
          icon={() => <svg></svg>}
          disabled={props.category?.isLocked}
        ></Winylo.Input>
        <label className={style.label}>Taux de compression</label>
        <div className={style.range}>
          <Range
            step={1}
            min={0}
            max={100}
            values={formik.values.photoCompression}
            onChange={(values) => setCompression(values)}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#c0c0c0",
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "14px",
                  width: "14px",
                  borderRadius: "50px",
                  backgroundColor: "var(--blue)",
                  outline: "none",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    textAlign: "center",
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "var(--blue)",
                    position: "absolute",
                    top: ".875rem",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                >
                  {props["aria-valuenow"]}%
                </div>
              </div>
            )}
          />
        </div>
        <div className={style.previewSize}>
          Taille de l'image : {currentSize > 1000000 ? (currentSize / 1000 / 1000).toFixed(2) + " Mo" : (currentSize / 1000).toFixed(2) + " Ko"}
        </div>
        <div
          className={style.previewImage}
          style={
            previewBase64
              ? {
                  backgroundImage: `url('${previewBase64}')`,
                }
              : {
                  backgroundImage: `url('${MY_DOMAIN}/compressionPreview.jpg')`,
                }
          }
        />
      </span>
      <div style={{ textAlign: "center" }}>
        <Winylo.Button type="submit" style={{ marginTop: "1.875rem" }} id="create-folder-in-modal">
          {props.category === undefined ? (props.parentCategory === undefined ? "Créer un dossier" : "Créer un sous-dossier") : "Modifier le dossier"}
        </Winylo.Button>
      </div>
    </form>
  );
}
