import { Category, ROLES, UserCategoryLevel } from "../../api/_type";

import { ReactComponent as FolderIcon } from "../../svg/folder.svg";
import { ReactComponent as SharedFolderIcon } from "../../svg/shared-folder.svg";
import { ReactComponent as OpenIcon } from "../../svg/angle-down-solid.svg";
import { ReactComponent as CloseIcon } from "../../svg/angle-up-solid.svg";
import { ReactComponent as ThreeDotsIcon } from "../../svg/three-dots.svg";

import style from "./FolderList.module.css";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import PopOver from "../PopOver/PopOver";
import { UserContextType, useUser } from "../../context/UserContext";
import { hasUserAccessCategory, MY_DOMAIN } from "../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";

function Folder({
  categorySelected,
  category,
  onClickOnCategory,
  editCategory,
  // shareCategory,
  connectCategory,
  downloadCategory,
  createSubCategory,
  deleteCategory,
  hasAccess,
}: {
  categorySelected?: Category;
  category: Category;
  onClickOnCategory: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, category: Category) => void;
  editCategory: (category: Category, type?: string) => void;
  // shareCategory: (category: Category) => void;
  connectCategory: (category: Category) => void;
  downloadCategory: (category: Category) => void;
  createSubCategory: (parentCategory: Category) => void;
  deleteCategory: (category: Category) => void;
  hasAccess?: boolean;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [optionsOpen, setOptionsOpen] = useState<boolean>(false);
  const [subFolderOptionsOpen, setSubFolderOptionsOpen] = useState<Category | undefined>(undefined);

  const [user] = useUser() as UserContextType;

  function canBeOpen(): boolean {
    return category.subCategories && category.subCategories.length > 0 ? true : false;
  }

  function openClose(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // e.stopPropagation();
    setOpen((b) => !b);
  }

  function openCloseOptions(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // e.stopPropagation();
    setOptionsOpen((b) => !b);
  }

  function openCloseSubFolderOptions(category: Category) {
    // e.stopPropagation();
    setSubFolderOptionsOpen(category);
  }

  function isSelected(p_category: Category) {
    return categorySelected === p_category;
  }

  return (
    <div className={style.categoryContainer}>
      <div className={style.category}>
        <div style={{ display: "flex", width: "100%" }}>
          <div onClick={openCloseOptions} className={classNames(style.folderOptions, style.threeDotsIcon)} style={{ flex: "2" }}>
            <ThreeDotsIcon width={"1rem"} height={"1rem"} />
          </div>
          <div
            className={style.folderOptions}
            style={{ flex: "98" }}
            onClick={(e) => onClickOnCategory(e, category)}
            onContextMenu={(e) => {
              e.preventDefault();
              openCloseOptions(e);
            }}
          >
            {category.sharedCategories.length > 0 ? (
              <SharedFolderIcon
                className={classNames(style.folderIcon, category.isPrivate ? style.folderPrivate : style.folderPublic)}
                onClick={() => setOpen(true)}
              />
            ) : (
              <FolderIcon
                className={classNames(style.folderIcon, category.isPrivate ? style.folderPrivate : style.folderPublic)}
                onClick={() => setOpen(true)}
              />
            )}
            <span className={classNames(style.label, isSelected(category) && style.selected)} onClick={() => setOpen(true)}>
              {category.label}
              {/* {category.isPrivate && <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={faLock} />} */}
            </span>
          </div>
        </div>
        {canBeOpen() && <div onClick={openClose}>{open ? <CloseIcon className={style.icon} /> : <OpenIcon className={style.icon} />}</div>}
        {optionsOpen && (
          <PopOver className={style.popOver} clickOutside={() => setOptionsOpen(false)}>
            {hasUserAccessCategory(user, category, UserCategoryLevel.LEVEL_EDIT) && (
              <div
                onClick={() => {
                  setOptionsOpen(false);
                  createSubCategory(category);
                }}
              >
                Créer un sous-dossier
              </div>
            )}
            {hasUserAccessCategory(user, category, UserCategoryLevel.LEVEL_EDIT) && (
              <div
                onClick={() => {
                  setOptionsOpen(false);
                  editCategory(category);
                }}
              >
                Paramétrer
              </div>
            )}
            <div
              onClick={() => {
                setOptionsOpen(false);
                downloadCategory(category);
              }}
            >
              Télécharger
            </div>
            {hasUserAccessCategory(user, category, UserCategoryLevel.LEVEL_EDIT) && (
              <div
                onClick={() => {
                  setOptionsOpen(false);
                  connectCategory(category);
                }}
              >
                Connecter
              </div>
            )}
            {(user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN)) && (
              <div
                onClick={() => {
                  setOptionsOpen(false);
                  deleteCategory(category);
                }}
              >
                Supprimer
              </div>
            )}
          </PopOver>
        )}
      </div>
      {open && (
        <div className={style.subCategories}>
          {category.subCategories?.map(
            (subCategory) => (
              <React.Fragment key={subCategory.id}>
                <div className={style.subCategory}>
                  <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                    <div
                      className={classNames(style.folderOptions, style.threeDotsIcon)}
                      style={{ flex: "2" }}
                      onClick={() => openCloseSubFolderOptions(subCategory)}
                    >
                      <ThreeDotsIcon width={"1rem"} height={"1rem"} />
                    </div>
                    <div
                      className={style.folderOptions}
                      style={{ flex: "98" }}
                      onClick={(e) => onClickOnCategory(e, subCategory)}
                      onContextMenu={(e) => {
                        e.preventDefault();
                        openCloseSubFolderOptions(subCategory);
                      }}
                    >
                      {subCategory.sharedCategories && subCategory.sharedCategories.length > 0 ? (
                        <SharedFolderIcon
                          className={classNames(style.folderIcon, subCategory.isPrivate ? style.folderPrivate : style.folderPublic)}
                        />
                      ) : (
                        <FolderIcon className={classNames(style.folderIcon, subCategory.isPrivate ? style.folderPrivate : style.folderPublic)} />
                      )}
                      <span className={classNames(style.label, isSelected(subCategory) && style.selected)}>
                        {subCategory.label}
                        {/* {category.isPrivate && <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={faLock} />} */}
                      </span>
                    </div>
                  </div>
                  {subFolderOptionsOpen?.id === subCategory.id && (
                    <PopOver className={style.popOver} clickOutside={() => setSubFolderOptionsOpen(undefined)}>
                      {hasUserAccessCategory(user, subCategory, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setSubFolderOptionsOpen(undefined);
                            editCategory(subCategory);
                          }}
                        >
                          Paramétrer
                        </div>
                      )}
                      {/* <div>Dupliquer</div> */}
                      <div
                        onClick={() => {
                          setOptionsOpen(false);
                          downloadCategory(subCategory);
                        }}
                      >
                        Télécharger
                      </div>
                      {hasUserAccessCategory(user, subCategory, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setOptionsOpen(false);
                            connectCategory(subCategory);
                          }}
                        >
                          Connecter
                        </div>
                      )}
                      {hasUserAccessCategory(user, subCategory, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setSubFolderOptionsOpen(undefined);
                            deleteCategory(subCategory);
                          }}
                        >
                          Supprimer
                        </div>
                      )}
                    </PopOver>
                  )}
                </div>
              </React.Fragment>
            )
            // )
          )}
        </div>
      )}
    </div>
  );
}

interface Props {
  categories: Category[] | undefined;
  editCategory: (category: Category) => void;
  // shareCategory: (category: Category) => void;
  connectCategory: (category: Category) => void;
  downloadCategory: (category: Category) => void;
  createSubCategory: (parentCategory: Category) => void;
  deleteCategory: (category: Category) => void;
  selectedCategory?: Category;
  setSelectedCategory: (category: Category) => void;
  setModalCategoryOpen: (isOpen: boolean) => void;
  paddingBottom?: string;
}

export default function FolderList(props: Props) {
  const [user] = useUser() as UserContextType;

  function onClickOnCategory(e: React.MouseEvent<HTMLDivElement, MouseEvent>, category: Category) {
    props.setSelectedCategory(category);
  }

  return (
    <div style={{ paddingBottom: props.paddingBottom || 0 }}>
      {props.categories !== undefined && props.categories.length > 0 ? (
        props.categories
          ?.filter((category) => category.superCategory === null)
          .map((category) => {
            if (category.subCategories && !Array.isArray(category.subCategories)) {
              const arrayAsObject: any = category.subCategories;
              const values: Category[] = Object.values(arrayAsObject);
              category.subCategories = [...values];
            }

            return (
              <React.Fragment key={category.id}>
                {hasUserAccessCategory(user, category, UserCategoryLevel.LEVEL_READ) && (
                  <Folder
                    categorySelected={props.selectedCategory}
                    category={category}
                    onClickOnCategory={onClickOnCategory}
                    editCategory={props.editCategory}
                    connectCategory={props.connectCategory}
                    downloadCategory={props.downloadCategory}
                    createSubCategory={props.createSubCategory}
                    deleteCategory={props.deleteCategory}
                  />
                )}
              </React.Fragment>
            );
          })
      ) : user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) ? (
        <div className={style.empty} onClick={() => props.setModalCategoryOpen(true)}>
          <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
          <span style={{ marginTop: "1rem" }}>Vous n'avez aucun dossier.</span>
          <span>Cliquez ici pour en créer un !</span>
        </div>
      ) : (
        <div className={style.empty} style={{ cursor: "default" }}>
          <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
          <span style={{ marginTop: "1rem" }}>Vous entreprise n'a aucun dossier.</span>
          <span>Demandez leur d'en créer un !</span>
        </div>
      )}
    </div>
  );
}
