import { useState, useContext, createContext } from "react";
import { Category } from "../api/_type";

export type CategoriesContextType = [Category[] | undefined, React.Dispatch<React.SetStateAction<Category[] | undefined>>];

const CategoriesContext = createContext<CategoriesContextType | undefined>(undefined);

export function CategoriesContextProvider({ children }: { children: React.ReactElement }) {
    const [categories, setCategories] = useState<Category[] | undefined>(undefined);

    return <CategoriesContext.Provider value={[categories, setCategories]}>{children}</CategoriesContext.Provider>;
}

export function useCategories() {
    return useContext(CategoriesContext);
}
