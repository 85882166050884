import { URL_HOST, checkStatus, parseFormData } from "./api";
import { BigCompany, Company, SmallUser, Stats, StorageData } from "./_type";

const companies = {
  getMyCompany: (): Promise<BigCompany> => {
    return fetch(`${URL_HOST}/api/companies/me`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getCompanyUsers: (): Promise<SmallUser[]> => {
    return fetch(`${URL_HOST}/api/companies/users`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateMyCompany: ({
    id,
    body,
  }: {
    id: number;
    body: {
      maxSizePhoto: number;
      maxSizeVideo: number;
      shelfLife: number;
      photoCompression: number;
      videoQuality: string;
    };
  }): Promise<Company> => {
    return fetch(`${URL_HOST}/api/companies/${id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: parseFormData(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getStats: (): Promise<Stats[]> => {
    return fetch(`${URL_HOST}/api/companies/stats`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getStorageData: (): Promise<StorageData> => {
    return fetch(`${URL_HOST}/api/companies/storage-data`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default companies;
