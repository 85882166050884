import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";
import { OnboradingEvents } from "./events.onboarding";

export function createAddMediaSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Ajouter un média</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 6;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "create-categories-1st",
      attachTo: { element: "#manage-medias", on: "right" },
      advanceOn: { selector: "#manage-medias", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            navigate("/folders");
            this.next();
          },
        },
      ],
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        <br/>
       Cliquez sur l\'onglet <span class="important-text">Gestion des médias</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-2nd",
      attachTo: { element: "#folders-container", on: "left" },
      advanceOn: { selector: "#folders-container > *", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            navigate("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector("#folders-container > *:first-child");
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        <br/>
       Ouvrez un dossier en cliquant dessus.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-3rd",
      attachTo: { element: "#button-add-media", on: "left" },
      advanceOn: { selector: "#button-add-media", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new CustomEvent(OnboradingEvents.CHOOSE_NO_FOLDER));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.getElementById("button-add-media");
            simulateMouseClick(element);
            // this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-3rd",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
      Cliquez sur le bouton <span class="important-text">ajouter des médias</span>.<br/>
      `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding + 10,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-5th",
      attachTo: { element: "#media-modal", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-5th",
      highlightClass: "onboarding-categories-5th-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
        <br/>
        Ici, donnez un <span class="important-text">nom</span> et une <span class="important-text">description</span> à vos médias.<br/>
        Sélectionnez un ou plusieurs fichiers à ajouter.
        `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [-50, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-7th",
      attachTo: { element: "#create-media-in-modal", on: "right" },
      advanceOn: { selector: "#create-media-in-modal", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            // document.dispatchEvent(new CustomEvent(OnboradingEvents.OPEN_FOLDER_MODAL));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.getElementById("create-media-in-modal");
            simulateMouseClick(element);
            document.dispatchEvent(new CustomEvent(OnboradingEvents.CLOSE_MEDIA_MODAL));
            // this.next();
          },
        },
      ],
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        <br/>
        Enfin, appuyez sur <span class="important-text">Ajouter des médias</span> et vos médias seront créés.
        `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-8th",
      attachTo: { element: "#medias-container", on: "right-start" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new CustomEvent(OnboradingEvents.OPEN_MEDIA_MODAL));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">6 sur ${stepsLength}</span><br/>
        <br/>
        Ici, vous retrouverez la liste de vos médias.<br/>
        <br/>
        Vous pouvez cliquer sur l'icône <img src="${MY_DOMAIN}/onboarding/three-dots.svg" width="14px" height="14px" /> pour <br/>
        modifier, déplacer, partager, télécharger et supprimer un média.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-9th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            navigate("/?onBoardingStart=2");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     navigate("/categories/create");
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">ajouter un média depuis l'ordinateur</span> !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel <span class="important-text">"Ajouter un média depuis l'application mobile"</span>.`,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
