import classNames from "classnames";
import style from "../css/Switch.module.css";

interface Props {
  checked?: boolean;
  defaultChecked?: boolean;
  onClick?: React.MouseEventHandler<HTMLInputElement>;
  className?: any;
  disabled?: boolean;
  title?: string;
}

export default function Switch({ checked, defaultChecked, onClick, className, disabled, title }: Props) {
  return (
    <label title={title} className={classNames(style.switch, className, disabled ? style.disabled : "")}>
      <input type="checkbox" checked={checked} defaultChecked={defaultChecked} onClick={onClick} readOnly disabled={disabled} />
      <span className={classNames(style.slider, disabled ? style.disabled : "")}></span>
    </label>
  );
}
