import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import MainView from "./pages/MainView/MainView";
import Login from "./pages/Auth/Login";
import ReturnLogin from "./pages/Auth/ReturnLogin";
import { UserContextType, useUser } from "./context/UserContext";
import { HasCheckLoginContextType, useHasCheckLogin } from "./context/HasCheckLoginContext";
import api, { URL_CORE } from "./api/api";
import { JWT_PERIOD } from "./utils/constantes";
import { ReactQueryDevtools } from "react-query/devtools";
import NeedLogin from "./utils/NeedLogin";
import Winylo from "@winylo/winylo-react-component";
import { formatUserRoleToString, MY_DOMAIN } from "./utils/utils";
import "toastify-js/src/toastify.css";
import TopBar from "./winylo/components/TopBar";
import SidePannel from "./components/SidePannel/SidePannel";
import Home from "./pages/Home/Home";
import RedirectToStore from "./pages/RedirectToStore/RedirectToStore";
import { ShepherdTour } from "react-shepherd";

const tourOptions = {
  defaultStepOptions: {
    cancelIcon: {
      enabled: true,
      label: "fg",
    },
  },
  useModalOverlay: true,
  exitOnEsc: true,
  keyboardNavigation: false,
};

function App() {
  const [user, setUser] = useUser() as UserContextType;
  const [, setHasCheckLogin] = useHasCheckLogin() as HasCheckLoginContextType;

  useEffect(() => {
    api.loginApi
      .getMe()
      .then((data) => {
        console.log("%cReconexion avec le JWT", "color: blue");

        setUser(data.user);
        setHasCheckLogin(true);
        refetchMe();
      })
      .catch((err) => {
        console.log(err);
        setHasCheckLogin(true);
        setUser(undefined);
      });

    function refetchMe() {
      api.loginApi
        .getMe()
        .then((data) => {
          console.log("%cAcualisation du JWT", "color: blue");

          localStorage.setItem("jwt", data.jwt);

          setUser(data.user);

          setTimeout(refetchMe, JWT_PERIOD);
        })
        .catch((err) => {
          console.log(err);
          setUser(undefined);
        });
    }
  }, [setUser, setHasCheckLogin]);

  const navigate = useNavigate();
  const location = useLocation();

  const freeUrl = ["/login"];

  function logout() {
    localStorage.removeItem("jwt");
    setUser(undefined);

    if (!freeUrl.includes(location.pathname) && !location.pathname.startsWith("/c/")) {
      navigate("/login#redirect=" + location.pathname);
    }
  }
  //

  return (
    <>
      <ShepherdTour steps={[]} tourOptions={tourOptions}>
        {user && (
          <TopBar
            brandLogoClick={() => (window.location.href = "/folders")}
            brandLogoSrc={`${MY_DOMAIN}/captureLogo.png`}
            brandLogoText="Photos & Vidéos"
            logout={logout}
            username={user.firstname + " " + user.lastname.substring(0, 1) + "."}
            userRole={formatUserRoleToString(user.roles)}
            actions={[
              {
                text: "Retours aux apps",
                onClick: () => {
                  window.location.href = URL_CORE;
                },
              },
            ]}
          />
        )}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/return-login" element={<ReturnLogin />} />
          <Route path="/redirect-to-store" element={<RedirectToStore />} />
          <Route
            path="/"
            element={
              <SidePannel>
                <NeedLogin>
                  <Home />
                </NeedLogin>
              </SidePannel>
            }
          />
          <Route
            path="/folders"
            element={
              <SidePannel>
                <NeedLogin>
                  <MainView />
                </NeedLogin>
              </SidePannel>
            }
          />
          <Route
            path="/folder/:folderId"
            element={
              <SidePannel>
                <NeedLogin>
                  <MainView />
                </NeedLogin>
              </SidePannel>
            }
          />
        </Routes>

        <ReactQueryDevtools />
      </ShepherdTour>
    </>
  );
}

export default App;
