import { checkStatus, URL_HOST } from "./api";
import { AuthorizedApp, SmallAuthorizedApp } from "./_type";

const authorizedapp = {
  getApps: (): Promise<AuthorizedApp[]> => {
    return fetch(`${URL_HOST}/api/authorized-app`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  getAppsPublic: (): Promise<SmallAuthorizedApp[]> => {
    return fetch(`${URL_HOST}/api/authorized-app/public`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  addApp: (name: string): Promise<AuthorizedApp> => {
    return fetch(`${URL_HOST}/api/authorized-app`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
        "content-type": "application/json",
      },
      body: JSON.stringify({ name }),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  deleteApp: (id: number): Promise<string> => {
    return fetch(`${URL_HOST}/api/authorized-app/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
  updateApp: ({
    id,
    body,
  }: {
    id: number;
    body: { name: string; hasAccess: boolean };
  }): Promise<AuthorizedApp> => {
    return fetch(`${URL_HOST}/api/authorized-app/${id}`, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
};

export default authorizedapp;
