import { useEffect, useState } from "react";

import { ReactComponent as Dot } from "./circle.svg";

import defaultStyle from "./Carousel.module.css";
import classNames from "classnames";
import { getBase64 } from "../../utils/utils";

interface Props {
  files: File[];
  style?: any;
  unselectedColor?: string;
  selectedColor?: string;
  isMini?: boolean;
  previewText?: string;
}

export default function Carousel({ files, style = defaultStyle, unselectedColor = "white", selectedColor = "#F4D03F", isMini, previewText }: Props) {
  const [currentPicture, setCurrentPicture] = useState(0);
  const [files64, setFiles64] = useState<string[]>([]);

  useEffect(() => {
    let promises = [];
    promises.push(setCurrentPicture(0));

    Promise.all(promises)
      .then(() => {
        setFiles64([]);
      })
      .then(() => {
        files.forEach((file) => {
          getBase64(file).then((base64: string) => {
            setFiles64((o) => [...o, base64]);
          });
        });
      });
  }, [files]);

  return (
    <>
      {files.length === 0 ? (
        <div
          style={{
            backgroundSize: "cover",
          }}
          className={classNames(style.productCover, style.empty)}
        >
          {previewText ? previewText : "Prévisualisation des médias"}
        </div>
      ) : (
        <div
          style={{
            backgroundImage: `url(${files64[currentPicture]})`,
          }}
          className={style.productCoverMultiple}
        >
          {files[currentPicture] && files[currentPicture].type.startsWith("video") ? (
            <video controls={isMini ? false : true} src={files64[currentPicture]} className={style.productCover} />
          ) : null}
          {files.length > 1 ? (
            <>
              <div className={classNames(style.dotContainer, style.left)}>
                {files.map((m, i) => (
                  <Dot
                    className={style.dot}
                    style={{
                      color: currentPicture === i ? selectedColor : unselectedColor,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentPicture(i);
                    }}
                    key={i}
                  />
                ))}
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      )}
    </>
  );
}
