import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../utils";
import { OnboradingEvents } from "./events.onboarding";

export function createAddMediaErrorSteps(navigate: any, setOnBoardingStart: (index: number) => void): ShepherdOptionsWithType[] {
  const completeText: string = "Faire le tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Oups !</span>`;

  const defaultDelay: number = 0;

  const stepsLength = 2;

  return [
    {
      id: "detailled-proposals-error-1st",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, 0);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
        Vous n'avez pas de <span class="important-text">dossier</span><br/>
        existantes et ne pouvez donc pas faire ce tutoriel.`,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "detailled-proposals-error-2nd",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-complete",
          text: completeText,
          action() {
            setOnBoardingStart && setOnBoardingStart(0);
            this.complete();
          },
        },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: true,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
        Vous pouvez faire le tutoriel pour <span class="important-text">créer et modifier un dossier</span><br/>
        afin de savoir comment en avoir.`,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
