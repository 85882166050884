import { checkStatus, URL_HOST } from "./api";
import { SharedCategory } from "./_type";

const sharedcategories = {
  create: (body: {
    authorizedApp: number;
    category: number;
  }): Promise<SharedCategory> => {
    return fetch(`${URL_HOST}/api/shared-categories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  delete: (id: number): Promise<string> => {
    return fetch(`${URL_HOST}/api/shared-categories/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.text());
  },
};

export default sharedcategories;
