import { ShepherdOptionsWithType } from "react-shepherd";
import { MY_DOMAIN, simulateMouseClick } from "../../utils/utils";
import { OnboradingEvents } from "./events.onboarding";

export function createFolderSteps(navigate: any): ShepherdOptionsWithType[] {
  const completeText: string = "Je m'entraîne";
  const nextTutoText: string = "Prochain tutoriel";
  const backText: string = `<img src="${MY_DOMAIN}/onboarding/caret-left-solid.svg" width="32" height="32"/>`;
  const nextText: string = `<img src="${MY_DOMAIN}/onboarding/caret-right-solid.svg" width="32" height="32"/>`;
  const title: string = `<span>Créer et modifier un dossier</span>`;

  const defaultDelay: number = 500;

  const stepsLength = 5;

  const overlayPadding = 5;
  const overlatRadius = 10;

  return [
    {
      id: "create-categories-1st",
      attachTo: { element: "#manage-medias", on: "right" },
      advanceOn: { selector: "#manage-medias", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          disabled: true,
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            navigate("/folders");
            this.next();
          },
        },
      ],
      highlightClass: "highlight",
      scrollTo: false,
      cancelIcon: {
        enabled: true,
        label: "",
      },
      title,
      text: [
        `<span class="important-text">1 sur ${stepsLength}</span><br/>
       Cliquez sur l\'onglet <span class="important-text">Gestion des médias</span> dans le menu à gauche.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 0] } }],
      },
      modalOverlayOpeningPadding: overlayPadding / 2,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-2nd",
      attachTo: { element: "#create-folder", on: "right" },
      advanceOn: { selector: "#create-folder", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            navigate("/");
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.getElementById("create-folder");
            simulateMouseClick(element);
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-2nd",
      highlightClass: "highlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">2 sur ${stepsLength}</span><br/>
       Cliquez sur le bouton <span class="important-text-yellow">Ajouter un nouveau dossier</span>.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-3rd",
      attachTo: { element: "#folder-modal", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            // var element = document.getElementById('create-folder');
            // simulateMouseClick(element);
            this.next();
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-3rd",
      highlightClass: "highlight-category-modal",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">3 sur ${stepsLength}</span><br/>
        <br/>
      Ici, donnez un <span class="important-text">nom</span> à votre dossier.<br/>
      Choisissez aussi le <span class="important-text">taux de compression</span> des images,<br/>ce qui permettra de réduire leur taille lors de l'enregistrement.<br/>
      <br/>
      Il en est de même pour les vidéos.<br/>
      Plus la <span class="important-text">qualité</span> est haute, plus la vidéo sera lourde.
      `,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding + 10,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-5th",
      attachTo: { element: "#create-folder-in-modal", on: "right" },
      advanceOn: { selector: "#create-folder-in-modal", event: "click" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            var element = document.querySelector("#create-folder-in-modal");
            simulateMouseClick(element);
            document.dispatchEvent(new CustomEvent(OnboradingEvents.CLOSE_FOLDER_MODAL));
          },
        },
      ],
      classes: "winylo-onboarding winylo-onboarding-categories-5th",
      highlightClass: "onboarding-categories-5th-hightlight",
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">4 sur ${stepsLength}</span><br/>
      Enfin, appuyez sur <span class="important-text">Créer un dossier</span> et votre dossier sera créé.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [-50, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-7th",
      attachTo: { element: "#right-folder-list", on: "right" },
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            window.scrollTo(0, 0);
            resolve(null);
          }, defaultDelay);
        });
      },
      canClickTarget: true,
      buttons: [
        {
          classes: "shepherd-button shepherd-button-back",
          text: backText,
          action() {
            document.dispatchEvent(new CustomEvent(OnboradingEvents.OPEN_FOLDER_MODAL));
            this.back();
          },
        },
        {
          classes: "shepherd-button shepherd-button-next",
          text: nextText,
          action() {
            this.next();
          },
        },
      ],
      scrollTo: false,
      title,
      text: [
        `<span class="important-text">5 sur ${stepsLength}</span><br/>
        Ici, vous retrouverez la liste de vos dossiers et sous-dossiers.<br/>
        <br/>
        Vous pouvez cliquer sur l'icône <img src="${MY_DOMAIN}/onboarding/three-dots.svg" width="14px" height="14px" /> pour <br/>modifier un dossier et créer des sous-dossiers.`,
      ],
      popperOptions: {
        modifiers: [{ name: "offset", options: { offset: [0, 25] } }],
      },
      modalOverlayOpeningPadding: overlayPadding,
      modalOverlayOpeningRadius: overlatRadius,
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    {
      id: "create-categories-9th",
      beforeShowPromise: function () {
        return new Promise(function (resolve) {
          setTimeout(function () {
            resolve(null);
          }, defaultDelay);
        });
      },
      buttons: [
        {
          classes: "shepherd-button shepherd-button-nexttutorial",
          text: nextTutoText,
          action() {
            navigate("/?onBoardingStart=1");
            this.complete();
          },
        },
        // {
        //   classes: "shepherd-button shepherd-button-complete",
        //   text: completeText,
        //   action() {
        //     navigate("/categories/create");
        //     this.complete();
        //   },
        // },
      ],
      classes: "winylo-onboarding",
      highlightClass: "highlight",
      scrollTo: false,
      title: `<span>Félicitations !</span>`,
      text: [
        `Vous avez terminé le tutoriel <span class="important-text">créer et modifier un dossier</span> !<br/><br/>
      Vous pouvez vous arrêter là ou bien continuer<br/>
      en lançant le tutoriel <span class="important-text">"Ajouter un média depuis l'ordinateur"</span>.`,
      ],
      when: {
        cancel: function () {
          navigate("/");
        },
      },
    }, ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // ...
  ];
}
