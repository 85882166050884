import { resolve } from "path";
import qs from "qs";
import { URL_HOST, checkStatus } from "./api";

import { Media, DownloadFile, MediaV2, PageItem, FilterType } from "./_type";

const MediaSortType = {
  [FilterType.DATE_RECENT]: {
    sort: "m.creationDate",
    direction: "DESC",
  },
  [FilterType.DATE_OLD]: {
    sort: "m.creationDate",
    direction: "ASC",
  },
  [FilterType.ALPHA_AZ]: {
    sort: "m.name",
    direction: "ASC",
  },
  [FilterType.ALPHA_ZA]: {
    sort: "m.name",
    direction: "DESC",
  },
};

const medias = {
  getMediasByCategory: ({ category, page, sort }: { category: number; page: number; sort?: FilterType }): Promise<PageItem<MediaV2>> => {
    return fetch(`${URL_HOST}/api/categories/${category}/medias?${qs.stringify({ page, ...MediaSortType[sort || FilterType.DATE_RECENT] })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getMediasByFilter: ({
    page,
    global,
    sort,
    authors,
    folders,
  }: {
    page?: number;
    global?: string;
    sort?: FilterType;
    authors?: number[];
    folders?: number[];
  }): Promise<PageItem<MediaV2>> => {
    return fetch(`${URL_HOST}/api/medias?${qs.stringify({ page, global, ...MediaSortType[sort || FilterType.DATE_RECENT], authors, folders })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteMedia: (id: number): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/medias/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },

  addMedia: ({ name, description, file, categoryId }: { name: string; description?: string; file: File; categoryId: number }): Promise<MediaV2> => {
    let data = new FormData();

    data.append("name", name);
    if (description) data.append("description", description);
    data.append("category", `{"id": ${categoryId}}`);
    data.append("file", file);

    return fetch(`${URL_HOST}/api/medias`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  updateMedia: ({
    id,
    name,
    description,
    isPublic,
    rotation,
  }: {
    id: number;
    name?: string;
    description?: string;
    isPublic?: boolean;
    rotation?: number;
  }): Promise<MediaV2> => {
    let data = new FormData();

    if (name) data.append("name", name);
    if (description) data.append("description", description);
    if (isPublic !== undefined) data.append("isPublic", isPublic.toString());
    if (rotation !== undefined) data.append("rotation", rotation.toString());

    return fetch(`${URL_HOST}/api/medias/${id}/update`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  moveMedia: ({ mediaId, categoryId }: { mediaId: number; categoryId: number }): Promise<Media> => {
    let data = new FormData();

    data.append("categoryId", categoryId.toString());

    return fetch(`${URL_HOST}/api/medias/${mediaId}/move`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  moveMedias: ({ mediasId, categoryId }: { mediasId: number[]; categoryId: number }): Promise<Media> => {
    let data = new FormData();

    data.append("categoryId", categoryId.toString());
    data.append("mediasId", JSON.stringify(mediasId));

    return fetch(`${URL_HOST}/api/medias/move`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  cloneMedia: ({ mediaId, categoriesId }: { mediaId: number; categoriesId: number[] }): Promise<MediaV2> => {
    let data = new FormData();

    data.append("categoriesId", JSON.stringify(categoriesId));

    // categoriesId.forEach((v) => {
    //     data.append("categoriesId", v.toString());
    // });

    return fetch(`${URL_HOST}/api/medias/${mediaId}/clone`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: data,
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  downloadMedias: ({ mediasId }: { mediasId: number[] }): Promise<DownloadFile | undefined> => {
    return fetch(`${URL_HOST}/api/medias/download`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({ mediasId: mediasId }),
    })
      .then(checkStatus)
      .then((res) => {
        return new Promise((resolve, reject) => {
          res?.blob().then((blob) => {
            const header = res.headers.get("Content-Disposition");
            const parts = header!.split(";");
            let filename = parts[1].split("=")[1];

            resolve({
              content: blob,
              name: filename,
            });
          });
        });
      });
  },
  deleteMedias: ({ mediasId }: { mediasId: number[] }): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/medias/delete`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({ mediasId: mediasId }),
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
};

export default medias;
