import Winylo from "@winylo/winylo-react-component";
import { faUser, faBell, faBellSlash, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "react-query";
import { MultiValueGenericProps, SingleValue } from "react-select";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import api from "../../api/api";
import { AccessType, Category, ROLES, SmallUser, UserCategory, UserOption, UserOptionColors } from "../../api/_type";
import { useDebounce } from "../../utils/utils";

import style from "./CategoryModal.module.css";
import StyledSelect from "../StyledSelect/StyledSelect";
import Switch from "../../winylo/components/Switch";
import { UserContextType, useUser } from "../../context/UserContext";

const AccessOptions = [
  { value: AccessType.READ, label: "Consulter" },
  { value: AccessType.WRITE, label: "Ajouter" },
  { value: AccessType.UPDATE, label: "Administrer" },
];
interface uceProps {
  index: number;
  uc: UserCategory;
  deleteUserCategory: UseMutateFunction<
    string | undefined,
    unknown,
    {
      userCategoryId: number;
    },
    unknown
  >;
  toggleIsNoticed: UseMutateFunction<
    string | undefined,
    unknown,
    {
      userId: number;
      categoryId: number;
    },
    unknown
  >;
  handleAccessSelectChange: (
    newValue: SingleValue<{
      value: AccessType;
      label: string;
    }>,
    userCategoryChangedId: number
  ) => void;
  isUserAdmin: boolean;
}

function UserCategoryElement(props: uceProps) {
  const [user] = useUser() as UserContextType;
  return (
    <tr key={props.index} className={style.userCategoriesTr}>
      <td colSpan={4} width={"100%"}>
        {`${props.uc.user.firstname} ${props.uc.user.lastname}`}
      </td>
      <td colSpan={3} width={"100%"}>
        <StyledSelect
          className={style.accessSelect}
          options={AccessOptions}
          value={AccessOptions.find((ao) => ao.value === props.uc.level)}
          onChange={(e: any) => props.handleAccessSelectChange(e, props.uc.id)}
          isSearchable={false}
          isDisabled={!props.isUserAdmin && props.uc.level >= 3}
        />
      </td>
      <td colSpan={3} width={"100%"}>
        <div
          className={style.actionIconContainer}
          onClick={(e) => {
            e.stopPropagation();
            props.toggleIsNoticed({ userId: props.uc.user.id, categoryId: props.uc.category.id });
          }}
        >
          <FontAwesomeIcon className={style.actionIcon} icon={props.uc.isNoticed ? faBell : faBellSlash} />
        </div>
      </td>
      <td colSpan={2} width={"100%"}>
        {(props.isUserAdmin || (user?.id != props.uc.user.id && props.uc.level < 3)) && (
          <div className={style.actionIconContainer} onClick={() => props.deleteUserCategory({ userCategoryId: props.uc.id })}>
            <FontAwesomeIcon title="Retirer du dossier" className={style.actionIcon} icon={faTrashCan} />
          </div>
        )}
      </td>
    </tr>
  );
}

interface ueProps {
  index: number;
  user: SmallUser;
  category?: Category;
  toggleIsNoticed: UseMutateFunction<
    string | undefined,
    unknown,
    {
      userId: number;
      categoryId: number;
    },
    unknown
  >;
}

function UserElement(props: ueProps) {
  return (
    <tr key={props.index} className={style.userCategoriesTr}>
      <td colSpan={4} width={"100%"}>
        {`${props.user.firstname} ${props.user.lastname}`}
      </td>
      <td colSpan={3} width={"100%"}>
        <div
          className={style.actionIconContainer}
          onClick={(e) => {
            e.stopPropagation();
            props.category && props.toggleIsNoticed({ userId: props.user.id, categoryId: props.category.id });
          }}
        >
          <FontAwesomeIcon
            className={style.actionIcon}
            icon={props.user.userCategories?.find((uc) => uc.category.id === props.category?.id)?.isNoticed ? faBell : faBellSlash}
          />
        </div>
      </td>
    </tr>
  );
}

interface Props {
  category?: Category;
}

export default function CategoryAccess(props: Props) {
  const queryClient = useQueryClient();
  const [user] = useUser() as UserContextType;
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(
    user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) || false
  );

  const [isReloading, setIsReloading] = useState<boolean>(false);
  const [hasSynchWithParent, setHasSynchWithParent] = useState<boolean>(false);

  const { data: companyUsers } = useQuery("company_users", api.companies.getCompanyUsers);
  const { data: userCategories, isLoading } = useQuery(
    "user_categories",
    () => props.category && api.usercategories.getUserCategoriesByCategory(props.category.id),
    {
      onSuccess: (data) => {},
    }
  );

  const [accessToCreate, setAccessToCreate] = useState<{ value: AccessType; label: string }>(AccessOptions[0]);

  const [, setSelectedUsersFilterInput] = useState<UserOption[]>([]);
  const [selectedUsersFilter, setSelectedUsersFilter] = useDebounce<UserOption[]>(
    [],
    (selectedUsers) => {
      setSelectedUsersFilterInput(selectedUsers);
    },
    500
  );

  const formatOptionUser = (data: UserOption, formatOptionLabelMeta: FormatOptionLabelMeta<UserOption>) => {
    return (
      <div className={style.option}>
        <FontAwesomeIcon className={style.userIcon} icon={faUser} />
        <span style={{ marginLeft: "0.5rem" }}>{data.label}</span>
      </div>
    );
  };

  const MultiValueContainer = (props: MultiValueGenericProps<UserOption>) => {
    return (
      <div
        className={style.multiValueContainer}
        style={{
          backgroundColor: props.data.color,
        }}
      >
        {props.children}
      </div>
    );
  };

  const { mutate: createUserCategory, isLoading: isCreating } = useMutation(api.usercategories.createUserCategory, {
    onSuccess: (data) => {
      queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
        if (!old) return undefined;
        old.unshift(data);
        return old;
      });
    },
  });

  const { mutate: updateUserCategory } = useMutation(api.usercategories.updateUserCategory, {
    onSuccess: (data) => {
      queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
        if (!old) return undefined;
        return old.map((uc) => {
          if (uc.id === data.id) {
            uc = data;
          }
          return uc;
        });
      });
    },
  });

  const { mutate: toggleIsPrivate } = useMutation(api.categories.togglePrivate, {
    onSuccess: (response, variables) => {
      queryClient.setQueryData<Category[] | undefined>("categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        if (props.category?.superCategory) {
          return old.map((c) => {
            c.subCategories?.map((sc) => {
              if (sc.id === props.category?.id) {
                sc.isPrivate = !sc.isPrivate;
              }
              return sc;
            });

            return c;
          });
        }

        return old.map((c) => {
          if (c.id === props.category?.id) {
            c.isPrivate = !c.isPrivate;

            c.subCategories?.map((sc) => {
              if (c.isPrivate && !sc.isPrivate) {
                sc.isPrivate = true;
              }

              if (sc.superCategory) {
                sc.superCategory.isPrivate = c.isPrivate;
              }
              return sc;
            });
          }
          return c;
        });
      });
    },
  });

  const { mutate: toggleIsNoticed } = useMutation(api.usercategories.toggleNotice, {
    onSuccess: (response, variables) => {
      props.category?.isPrivate
        ? queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
            if (!old) return undefined;

            return old.map((uc) => {
              if (uc.user.id === variables.userId && uc.category.id === variables.categoryId) {
                uc.isNoticed = !uc.isNoticed;
              }
              return uc;
            });
          })
        : queryClient.invalidateQueries("company_users");
    },
  });

  const { mutate: deleteUserCategory } = useMutation(api.usercategories.deleteUserCategory, {
    onSuccess: (data, variables) => {
      queryClient.setQueryData<UserCategory[] | undefined>("user_categories", (old: UserCategory[] | undefined) => {
        if (!old) return undefined;
        return old.filter((uc) => uc.id !== variables.userCategoryId);
      });
    },
  });

  const { mutate: cloneUserCategories } = useMutation(api.categories.cloneUserCategoriesOfCategory, {
    onMutate: () => {
      setIsReloading(true);
    },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries("user_categories").then(() => {
        setIsReloading(false);
        setHasSynchWithParent(true);
      });
    },
  });

  function handleCreateClick() {
    const selectedUsersFilterTemp: UserOption[] = structuredClone(selectedUsersFilter);
    setSelectedUsersFilter([]);
    selectedUsersFilterTemp.map((suf) => {
      props.category && createUserCategory({ userId: suf.value, categoryId: props.category.id, level: accessToCreate.value.valueOf() });
    });
  }

  function handleAccessSelectChange(
    newValue: SingleValue<{
      value: AccessType;
      label: string;
    }>,
    userCategoryChangedId: number
  ) {
    updateUserCategory({ userCategoryId: userCategoryChangedId, level: newValue?.value.valueOf() });
  }

  return (
    <div className={style.categoryModal} style={{ height: "36.4629rem", overflowY: "scroll" }}>
      <div className={style.privateDiv}>
        <Switch
          className={style.privateSwitch}
          checked={props.category ? props.category.isPrivate : false}
          defaultChecked={false}
          onClick={() => props.category && toggleIsPrivate({ categoryId: props.category.id })}
          disabled={!isUserAdmin || (props.category?.superCategory ? props.category?.superCategory?.isPrivate : false)}
          title={props.category?.superCategory?.isPrivate ? "La catégorie parent est privée !" : "Changer la visibilité"}
        />
        <span style={{ marginLeft: "1rem", fontWeight: 600 }}>Dossier privé</span>
        {isUserAdmin && props.category?.isPrivate && !hasSynchWithParent && props.category?.superCategory && (
          <span
            className={style.cloneTxt}
            onClick={() =>
              props.category?.superCategory?.id && cloneUserCategories({ category: props.category?.id, target: props.category?.superCategory?.id })
            }
            style={{ marginLeft: "1rem" }}
          >
            Synchroniser les accès avec <span style={{ fontWeight: 600 }}>{props.category.superCategory.label}</span>
          </span>
        )}
      </div>
      {props.category?.isPrivate && (
        <>
          <div style={{ fontWeight: 600, marginBottom: "0.75rem" }}>Ajouter des utilisateurs</div>
          <div style={{ display: "flex", justifyContent: "stretch", alignItems: "center" }}>
            <StyledSelect
              options={
                companyUsers
                  ? [
                      ...(companyUsers
                        ?.filter((u) => {
                          return !userCategories?.some((uc) => uc.user.id === u.id);
                        })
                        .map((u) => {
                          const linkedCategory = u.userCategories?.find((uc) => {
                            return uc.category.id === props.category?.id;
                          });
                          let level = 0;

                          if (linkedCategory) {
                            level = linkedCategory.level;
                          }

                          return {
                            value: u.id,
                            label: `${u.firstname} ${u.lastname}`,
                            color: UserOptionColors[Math.floor(Math.random() * UserOptionColors.length)],
                            rightsLevel: level,
                          };
                        }) || []),
                    ].flat()
                  : []
              }
              className={style.searchSelect}
              value={selectedUsersFilter || []}
              isSearchable={true}
              onChange={(value) => {
                setSelectedUsersFilter(value as UserOption[]);
              }}
              isMulti
              formatOptionLabel={formatOptionUser as any}
              components={{ MultiValueContainer } as any}
            />
            <StyledSelect
              className={style.accessSelect}
              options={isUserAdmin ? AccessOptions : [AccessOptions[0], AccessOptions[1]]}
              defaultValue={AccessOptions[0]}
              onChange={(e: any) => e && setAccessToCreate(e)}
              isSearchable={false}
            />
            <Winylo.Button style={{ marginRight: "1rem" }} format="square" loading={isCreating} onClick={() => handleCreateClick()}>
              Ajouter
            </Winylo.Button>
          </div>
        </>
      )}

      <table className={style.userCategoriesTable}>
        {!isReloading && !isLoading ? (
          props.category?.isPrivate ? (
            userCategories && userCategories.length > 0 ? (
              <>
                <thead style={{ fontWeight: 600 }}>
                  <th colSpan={4}>Nom</th>
                  <th colSpan={3}>Accès</th>
                  <th colSpan={3}>Notifié</th>
                  <th colSpan={2}></th>
                </thead>
                <tbody>
                  {userCategories &&
                    userCategories.map((uc, index) => {
                      return (
                        <UserCategoryElement
                          index={index}
                          uc={uc}
                          deleteUserCategory={deleteUserCategory}
                          toggleIsNoticed={toggleIsNoticed}
                          handleAccessSelectChange={handleAccessSelectChange}
                          isUserAdmin={isUserAdmin}
                        />
                      );
                    })}
                </tbody>
              </>
            ) : (
              <tbody style={{ position: "relative" }}>
                <tr>
                  <td colSpan={4} className={style.empty}>
                    Aucun droit n'est accordé sur ce dossier.
                  </td>
                </tr>
              </tbody>
            )
          ) : (
            <>
              <thead style={{ fontWeight: 600 }}>
                <th colSpan={4}>Nom</th>
                <th colSpan={3}>Notifié</th>
              </thead>
              <tbody>
                {companyUsers &&
                  companyUsers.map((u, index) => {
                    return <UserElement index={index} user={u} category={props.category} toggleIsNoticed={toggleIsNoticed} />;
                  })}
              </tbody>
            </>
          )
        ) : (
          <>Chargement en cours...</>
        )}
      </table>
    </div>
  );
}
