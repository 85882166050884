import Winylo from "@winylo/winylo-react-component";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Select, { ActionMeta, NoticeProps, components, MultiValueGenericProps } from "react-select";
import api from "../../api/api";
import { Category } from "../../api/_type";

interface Props {
  open: boolean;
  onClose: () => void;
  category: Category;
  setLoaderState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ConnectFolderModal(props: Props) {
  const queryClient = useQueryClient();

  const { data: authorizedApps } = useQuery(["authorizedApps"], () => api.authorizedApp.getAppsPublic());

  const { mutate: addSharedCategory, isLoading: isAdding } = useMutation(api.sharedCategories.create, {
    onMutate: () => {
      props.setLoaderState(true);
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData<Category[] | undefined>("categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        return old.map((c) => {
          c.id === variables.category && c.sharedCategories.push(data);
          return c;
        });
      });

      queryClient.setQueryData<Category[] | undefined>("filtered_categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        return old.map((c) => {
          c.id === variables.category && c.sharedCategories.push(data);
          return c;
        });
      });

      props.setLoaderState(false);
    },
  });

  const { mutate: deleteSharedCategory, isLoading: isDeleting } = useMutation(api.sharedCategories.delete, {
    onMutate: () => {
      props.setLoaderState(true);
    },
    onSuccess: (data, variable) => {
      queryClient.setQueryData<Category[] | undefined>("categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        return old.map((c) => {
          c.sharedCategories = c.sharedCategories.filter((sc) => sc.id !== variable);
          return c;
        });
      });

      queryClient.setQueryData<Category[] | undefined>("filtered_categories", (old: Category[] | undefined) => {
        if (!old) return undefined;

        return old.map((c) => {
          c.sharedCategories = c.sharedCategories.filter((sc) => sc.id !== variable);
          return c;
        });
      });

      props.setLoaderState(false);
    },
  });

  function onClose() {
    // queryClient.invalidateQueries("categories");
    // queryClient.invalidateQueries("filtered_categories");
    if (props.onClose !== undefined) props.onClose();
  }

  function handleChange(action: ActionMeta<{ value: any; label: string }>) {
    if (action.action === "select-option") {
      addSharedCategory({ authorizedApp: action.option!.value, category: props.category.id });
    } else if (action.action === "remove-value") {
      let removedSharedCategory = props.category.sharedCategories.find(
        (sharedCategory) => sharedCategory.authorizedApp.id === action.removedValue!.value
      );
      if (removedSharedCategory) deleteSharedCategory(removedSharedCategory.id);
    }
  }

  return (
    <Winylo.Modal style={{ content: { height: "40vh" } }} isOpen={props.open} onClose={onClose} title="Connecter le dossier">
      <p>Les médias de ce dossier {props.category?.subCategories && "et sous-dossiers"} seront accessibles sur les applications sélectionnées.</p>
      {authorizedApps && (
        <Select
          noOptionsMessage={() => "Aucune application trouvée"}
          isDisabled={isAdding || isDeleting}
          onChange={(newValue, action) => handleChange(action)}
          isSearchable={false}
          options={authorizedApps?.map((authorizedApp) => {
            return { value: authorizedApp.id, label: authorizedApp.name };
          })}
          defaultValue={props.category.sharedCategories.map((sharedCategory) => {
            return { value: sharedCategory.authorizedApp.id, label: sharedCategory.authorizedApp.name };
          })}
          isMulti
          isClearable={false}
          placeholder="Sélectionner une application"
        ></Select>
      )}
    </Winylo.Modal>
  );
}
