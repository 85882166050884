import qs from "qs";
import { URL_HOST, checkStatus, parseFormData } from "./api";

import { Category, PageItem, DownloadFile, VIDEO_QUALITY } from "./_type";

const categories = {
  getCategories: (): Promise<Category[]> => {
    return fetch(`${URL_HOST}/api/categories`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getCategoriesByFilter: ({ page, global, folders }: { page?: number; global?: string; folders?: number[] }): Promise<PageItem<Category>> => {
    return fetch(`${URL_HOST}/api/categories/search?${qs.stringify({ page, global, folders })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteCategory: (id: number): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/categories/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  updateCategory: ({
    id,
    body,
  }: {
    id: number;
    body: {
      label?: string;
      photoCompression: number;
      videoQuality: null | VIDEO_QUALITY | string;
    };
  }): Promise<Category> => {
    return fetch(`${URL_HOST}/api/categories/${id}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: parseFormData(body),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  createCategory: ({
    superId,
    label,
    photoCompression,
    videoQuality,
  }: {
    superId?: number;
    label: string;
    photoCompression: number;
    videoQuality: null | VIDEO_QUALITY | string;
  }): Promise<Category> => {
    // TODO: Dégager la company d'ici
    let body: {
      label: string;
      superCategory?: string;
      photoCompression: number;
      videoQuality: null | VIDEO_QUALITY | string;
    } = {
      label,
      photoCompression,
      videoQuality,
    };

    if (superId !== undefined) {
      body.superCategory = `{"id": ${superId}}`;
    }
    return fetch(`${URL_HOST}/api/categories`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: parseFormData(body),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  downloadMedias: (id: number): Promise<DownloadFile | undefined> => {
    return fetch(`${URL_HOST}/api/categories/${id}/download`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => {
        return new Promise((resolve, reject) => {
          res?.blob().then((blob) => {
            const header = res.headers.get("Content-Disposition");
            const parts = header!.split(";");
            let filename = parts[1].split("=")[1];

            resolve({
              content: blob,
              name: filename,
            });
          });
        });
      });
  },
  togglePrivate: ({ categoryId }: { categoryId: number }): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/categories/${categoryId}/private`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  cloneUserCategoriesOfCategory: ({ category, target }: { category: number; target: number }): Promise<Category> => {
    return fetch(`${URL_HOST}/api/categories/${category}/clone?${qs.stringify({ target })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res.json());
  },
  purgeCategory: (id: number): Promise<Category> => {
    return fetch(`${URL_HOST}/api/categories/${id}/purge`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
};

export default categories;
