import { Category, ROLES, User, UserCategoryLevel } from "../api/_type";
import { CSSProperties, useState } from "react";
import { ShepherdOptionsWithType, Tour } from "react-shepherd";
import Compressor from "compressorjs";

export const MY_DOMAIN = window.location.protocol + "//" + window.location.hostname + (window.location.port !== "" ? `:${window.location.port}` : "");

export function parseHash() {
  return new URLSearchParams(window.location.hash.substr(1));
}

export function parseQuery() {
  return new URLSearchParams(window.location.search);
}

const patterns = [
  new RegExp(/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3,8}Z\b/),
  new RegExp(/\b\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}-\d{2}-\d{2}s\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}.d{3,8}Z\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}T\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}\/\d{2}\/\d{2}s\d{2}:\d{2}:\d{2}\b/),
  new RegExp(/\b\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b\d{4}-(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1])-(0[1-9]|1[0-2])-\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])-\d{4}\b/),

  new RegExp(/\b\d{4}\/(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\b/),
  new RegExp(/\b\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}\b/),

  new RegExp(/\b\d{4}.(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])\b/),
  new RegExp(/\b\d{4}.(0[1-9]|[1-2][0-9]|3[0-1]).(0[1-9]|1[0-2])\b/),
  new RegExp(/\b(0[1-9]|[1-2][0-9]|3[0-1]).(0[1-9]|1[0-2]).\d{4}\b/),
  new RegExp(/\b(0[1-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1]).\d{4}\b/),

  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9](:[0-5][0-9]).\d{3,6}\b/),
  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9](:[0-5][0-9])\b/),
  new RegExp(/\b(?:2[0-3]|[01][0-9]):[0-5][0-9]\b/),

  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9](:[0-5][0-9]).\d{3,6}\b/),
  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9](:[0-5][0-9])\b/),
  new RegExp(/\b(?:1[012]|0[0-9]):[0-5][0-9]\b/),
];

const format = [
  "YYYY-mm-ddTHH:MM:ss.Z",
  "YYYY-mm-ddTHH:MM:ss",
  "YYYY-mm-dd HH:MM:ss",
  "YYYY/mm/ddTHH:MM:ss.uZ",
  "YYYY/mm/ddTHH:MM:ss",
  "YYYY/mm/dd HH:MM:ss",
  "YYYY-mm-dd",
  "YYYY-dd-mm",
  "dd-mm-YYYY",
  "mm-dd-YYYY",

  "YYYY/dd/mm",
  "YYYY/mm/dd",
  "dd/mm/YYYY",
  "mm/dd/YYYY",

  "YYYY.mm.dd",
  "YYYY.dd.mm",
  "dd.mm.YYYY",
  "mm.dd.YYYY",

  // for 24-hour | hours seconds
  "HH:MM:ss.ms",
  "HH:MM:ss",
  "HH:MM",

  // for 12-hour | hours seconds
  "hh:MM:ss.ms",
  "hh:MM:ss",
  "hh:MM",
];

export function dateStringToDate(d: string | Date): Date {
  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    let i = 0;
    let finded = false;

    while (i < patterns.length && !finded) {
      if (d.match(patterns[i])) {
        finded = true;
      } else {
        i++;
      }
    }

    return parseDate(d, format[i]);
  }

  function parseDate(date: string, format: string): Date {
    let parsedDate = new Date();

    let indexDate = format.indexOf("YYYY");
    if (indexDate !== -1) {
      parsedDate.setFullYear(parseInt(date.substring(indexDate, indexDate + 4)));
    }

    indexDate = format.indexOf("mm");
    if (indexDate !== -1) {
      parsedDate.setMonth(parseInt(date.substring(indexDate, indexDate + 2)) - 1);
    }

    indexDate = format.indexOf("dd");
    if (indexDate !== -1) {
      parsedDate.setDate(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("HH");
    if (indexDate !== -1) {
      parsedDate.setHours(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("MM");
    if (indexDate !== -1) {
      parsedDate.setMinutes(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("ss");
    if (indexDate !== -1) {
      parsedDate.setSeconds(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    return parsedDate;
  }

  return date;
}

export function dateToString(d: string | Date, full?: boolean): string {
  if (d === "") return "";

  let date: Date;

  if (d instanceof Date) {
    date = d;
  } else {
    let i = 0;
    let finded = false;

    while (i < patterns.length && !finded) {
      if (d.match(patterns[i])) {
        finded = true;
      } else {
        i++;
      }
    }

    if (!finded) {
      return "Date invalide";
    }

    date = parseDate(d, format[i]);
  }

  function parseDate(date: string, format: string): Date {
    let parsedDate = new Date();

    let indexDate = format.indexOf("YYYY");
    if (indexDate !== -1) {
      parsedDate.setFullYear(parseInt(date.substring(indexDate, indexDate + 4)));
    }

    indexDate = format.indexOf("mm");
    if (indexDate !== -1) {
      parsedDate.setMonth(parseInt(date.substring(indexDate, indexDate + 2)) - 1);
    }

    indexDate = format.indexOf("dd");
    if (indexDate !== -1) {
      parsedDate.setDate(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("HH");
    if (indexDate !== -1) {
      parsedDate.setHours(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("MM");
    if (indexDate !== -1) {
      parsedDate.setMinutes(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    indexDate = format.indexOf("ss");
    if (indexDate !== -1) {
      parsedDate.setSeconds(parseInt(date.substring(indexDate, indexDate + 2)));
    }

    return parsedDate;
  }

  return `${zero(date.getDate())}/${zero(date.getMonth() + 1)}/${date.getFullYear()}${
    full ? ` -  ${zero(date.getHours())}h${zero(date.getMinutes())}` : ""
  }`;
}
function zero(int: number): string {
  return int < 10 ? "0" + int : "" + int;
}

export function stringToSize(size: string | number) {
  let initial;

  if (typeof size === "string") {
    initial = parseInt(size);
  } else {
    initial = size;
  }

  let reste = initial;

  let extensions = ["o", "ko", "Mo", "Go", "To"];
  let currentExtension = 0;

  while (reste > 1000) {
    currentExtension++;
    reste = Math.trunc(reste / 10) / 100;
  }

  return reste + " " + extensions[currentExtension];
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result as string);
    };
    reader.onerror = function (error) {
      reject("Error: " + error);
    };
  });
}

export function formatUserRoleToString(roles: ROLES[]) {
  if (roles.includes(ROLES.ROLE_COMPANY_ADMIN)) {
    return "Admin. Entreprise";
  }

  if (roles.includes(ROLES.ROLE_APPLICATION_ADMIN)) {
    return "Admin. Application";
  }

  return "Utilisateur";
}

export function useDebounce<T>(initialValue: T, funcToSend: (arg: T) => void, time: number = 500, funcImmediate?: (arg: T) => void) {
  // Création du state local
  const [localState, setLocalState] = useState<T>(initialValue);
  const [currentTimout, setCurrentTimout] = useState<undefined | NodeJS.Timeout>(undefined);

  // Récupération du changement
  function handleChange(value: T | ((val: T) => T)): void {
    // Change le state local
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);

    if (funcImmediate !== undefined) {
      funcImmediate(valueToStore);
    }

    // Si il y a déjà un timout, on l'annule
    if (currentTimout) {
      clearInterval(currentTimout);
    }

    // On crée un nouveau timout
    setCurrentTimout(setTimeout(() => funcToSend(valueToStore), time));
  }

  function simpleChange(value: T | ((val: T) => T)): void {
    const valueToStore = value instanceof Function ? value(localState) : value;
    setLocalState(valueToStore);
  }

  return [localState, handleChange, simpleChange] as const;
}

export function toFileArray(fileList: FileList | null) {
  let fileArray: File[] = [];

  if (fileList) {
    for (let i = 0; i < fileList.length; i++) {
      fileArray.push(fileList[i]);
    }
  }

  return fileArray;
}

export function copyStringToClipboard(str: string) {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(str);
  } else {
    var el = document.createElement("textarea");

    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";

    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
  }
}

const mouseClickEvents = ["mousedown", "click", "mouseup"];

export function simulateMouseClick(element: Element | null) {
  if (element === null) return;

  mouseClickEvents.forEach((mouseEventType) =>
    element.dispatchEvent(
      new MouseEvent(mouseEventType, {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      })
    )
  );
}

export function startOnBoardingTour(tour: Tour | null, steps: ShepherdOptionsWithType[], start: boolean = true, startingStep?: string) {
  if (tour) {
    tour.isActive() && tour.cancel();
    tour.steps = [];
    tour.addSteps(steps);
  }

  if (start) {
    tour?.start();
  } else if (startingStep) {
    tour?.show(startingStep);
  }
}

export function hasUserAccessCategory(user: User | undefined, category: Category | undefined, level: UserCategoryLevel): boolean {
  return (
    isAdmin(user) ||
    (!category?.isPrivate ? (level <= 2 ? true : false) : !!user?.userCategories?.some((uc) => uc.category.id === category?.id && uc.level >= level))
  );
}

export function isAdmin(user: User | undefined): boolean {
  if (user === undefined) return false;

  return user.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) || user.roles.includes(ROLES.ROLE_ADMIN);
}
