import { useInfiniteQuery, useQuery } from "react-query";

import style from "./Home.module.css";
import { title } from "process";
import { string } from "yup";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { patchNotes } from "../../utils/patchNotes";
import { dateToString, isAdmin, MY_DOMAIN, startOnBoardingTour } from "../../utils/utils";
import Winylo from "@winylo/winylo-react-component";
import { AwesomeQRCode } from "@awesomeqr/react";
import { useLocation, useNavigate } from "react-router-dom";
import { ShepherdTourContext } from "react-shepherd";
// import { useHistory } from "react-router-dom";
import { createFolderSteps } from "../../utils/onboarding/create-folder.steps";
import qs from "qs";
import api from "../../api/api";
import { createAddMediaErrorSteps } from "../../utils/onboarding/add-media-error.steps";
import { createAddMediaSteps } from "../../utils/onboarding/add-media.steps";
import { createAddMediasMobileSteps } from "../../utils/onboarding/add-media-mobile.steps";
import { OnboradingEvents } from "../../utils/onboarding/events.onboarding";
import { Patchnote, ROLES } from "../../api/_type";
import { UserContextType, useUser } from "../../context/UserContext";
import { faArrowRightArrowLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import mediaLogo from "./img/media-icon.png";
import kaizenLogo from "./img/kaizen-icon.png";
import catalogueLogo from "./img/catalogue-icon.png";
import PatchnoteItem from "./PatchnoteItem";

interface Props {
  title: string;
  children?: ReactElement;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  id?: string;
  className?: string;
}

function HomeContainer(props: Props): ReactElement {
  return (
    <div className={classNames(style.homeContainer, props.className, props.handleClick && style.clickable)} onClick={props.handleClick} id={props.id}>
      <div className={style.homeContainerTitle}>{props.title}</div>
      <div className={style.homeContainerChildren}>{props.children}</div>
    </div>
  );
}

export default function Home(): ReactElement {
  const [patchNotes, setPatchNotes] = useState<Patchnote[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [isModalMobileAppOpen, setIsModalMobileAppOpen] = useState<boolean>(false);

  const tour = useContext(ShepherdTourContext);
  // const history = useHistory();
  const navigate = useNavigate();
  const [user] = useUser() as UserContextType;

  const [onBoardingStart, setOnBoardingStart] = useState<number>(-1);

  let queryParams = useQueryParams();

  function useQueryParams() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const { isLoading, hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ["patchNotes"],
    ({ pageParam = 1 }) => api.patchnotes.getPatchnotes({ page: pageParam }),
    {
      onSuccess: (data) => {
        setPatchNotes(data.pages.map((p) => p.items).flat());
        setTotalCount(data.pages[0].pagination.totalCount);
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
    }
  );

  function startOnboardingAddMedia() {
    api.categories.getCategories().then((res) => {
      if (res.length > 0) {
        startOnBoardingTour(tour, createAddMediaSteps(navigate));
      } else {
        startOnBoardingTour(tour, createAddMediaErrorSteps(navigate, setOnBoardingStart));
      }
    });
  }

  useEffect(() => {
    if (onBoardingStart !== -1) {
      switch (onBoardingStart) {
        case 0 && user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) && user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN):
          startOnBoardingTour(tour, createFolderSteps(navigate));
          break;
        case 1:
          startOnboardingAddMedia();
          break;
        case 2:
          startOnBoardingTour(tour, createAddMediasMobileSteps(navigate));
          break;
        default:
          console.log(`%conBoardinStart : bad index (${onBoardingStart})`, "color: red");
          break;
      }
      setOnBoardingStart(-1);
    }
  }, [onBoardingStart]);

  function openDownloadAppModal() {
    setIsModalMobileAppOpen(true);
  }

  function closeDownloadAppModal() {
    setIsModalMobileAppOpen(false);
  }

  function checkDate(index: number, array: Patchnote[], patchnote: Patchnote) {
    return index === 0 || dateToString(patchnote.scheduledAt) !== dateToString(array[index - 1]?.scheduledAt);
  }

  useEffect(() => {
    let startIndex = qs.parse(window.location.search, { ignoreQueryPrefix: true }).onBoardingStart;
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }

    document.addEventListener(OnboradingEvents.OPEN_DOWNLOAD_APP_MODAL, openDownloadAppModal);
    document.addEventListener(OnboradingEvents.CLOSE_DOWNLOAD_APP_MODAL, closeDownloadAppModal);

    return () => {
      document.removeEventListener(OnboradingEvents.OPEN_DOWNLOAD_APP_MODAL, openDownloadAppModal);
      document.removeEventListener(OnboradingEvents.CLOSE_DOWNLOAD_APP_MODAL, closeDownloadAppModal);
    };
  }, []);

  useEffect(() => {
    let startIndex = queryParams.get("onBoardingStart");
    if (startIndex && !isNaN(startIndex as any)) {
      setOnBoardingStart(parseInt(startIndex as string));
    }
  }, [queryParams]);

  function ContainerTutorial() {
    return (
      <>
        <span className={classNames(style.textContent)}>Découvrez les tutoriels participatifs</span>
        <div className={style.itemList}>
          {user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) && user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) && (
            <div className={style.listItem} onClick={() => setOnBoardingStart(0)}>
              <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
              Créer et modifier un dossier
            </div>
          )}
          <div className={style.listItem} onClick={() => setOnBoardingStart(1)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Ajouter un média depuis l'ordinateur
          </div>
          <div className={classNames(style.listItem)} onClick={() => setOnBoardingStart(2)}>
            <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
            Ajouter un média depuis l'application mobile
          </div>
        </div>
      </>
    );
  }

  const ContainerPatchNotes = () => {
    function getPreviews() {
      return patchNotes.slice(0, 6).map((pn) => <PatchnoteItem patchnote={pn} short />);
    }

    return (
      <>
        <span className={style.textContent}>Découvrez les dernières nouveautés</span>
        <div className={style.itemList}>
          {patchNotes ? <>{getPreviews()}</> : <div>‎ </div>}
          <span className={style.clickableItem} style={{ display: "flex", justifyContent: "center" }}>
            Voir plus
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={style.mainContainer}>
        <Winylo.Card style={{ width: "90%", marginTop: "50px", maxWidth: "93.75rem" }}>
          <div className={style.container}>
            {isAdmin(user) && (
              <HomeContainer title="Premiers pas" className={style.tutoContent}>
                <ContainerTutorial />
              </HomeContainer>
            )}
            <HomeContainer title="AppStore et PlayStore" handleClick={() => setIsModalMobileAppOpen(true)}>
              <>
                <span className={style.textContent}>Téléchargez l'application mobile</span>

                <span className={style.textContent}></span>
                <div className={style.itemList}>
                  <div className={style.listItem} style={{ fontWeight: "600" }}>
                    <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                    Application mobile gratuite
                  </div>
                  <div className={style.listItem}>
                    <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                    Indispensable pour une utilisation optimale
                  </div>
                  <div className={style.listItem}>
                    <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                    Equipez vos collaborateurs sur le terrain avec notre application mobile
                  </div>
                  <div className={style.listItem}>
                    <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                    Alimentez votre galerie Photos & Vidéos à tous moments
                  </div>
                </div>
              </>
            </HomeContainer>
            <HomeContainer title="Mises à jour" handleClick={() => setIsModalUpdateOpen(true)}>
              <ContainerPatchNotes />
            </HomeContainer>
            <HomeContainer title="Applications connectables">
              <>
                <span className={style.textContent}>Nos applications sont connectables entre elles</span>
                <div className={style.itemContainer}>
                  <div className={style.linkableContainer}>
                    <img src={mediaLogo} className={classNames(style.linkableLogo, style.mediaLogo)} />
                    <FontAwesomeIcon icon={faArrowRightArrowLeft} className={style.linkableIcon} />
                    <img src={kaizenLogo} className={classNames(style.linkableLogo, style.kaizenLogo)} />
                    <img src={catalogueLogo} className={classNames(style.linkableLogo, style.catalogueLogo)} />
                  </div>
                </div>
              </>
            </HomeContainer>
            <div style={{ flexBasis: "32%" }}></div>
            <div style={{ flexBasis: "32%" }}></div>
            {/* <div className={style.homeContainerEmpty}></div> */}
            {/* <div className={style.homeContainerEmpty}></div> */}

            {/* <HomeContainer title="Applications mobile">
              <ContainerMobileApps />
            </HomeContainer> */}
            {/* <div className={style.homeContainerEmpty}></div>
            <div className={style.homeContainerEmpty}></div> */}
            {/* <HomeContainer title="Applications connectables">
              <ContainerLinkableApps />
            </HomeContainer>
            <HomeContainer title="Tutoriel">
              <ContainerTutorial />
            </HomeContainer> */}
          </div>
        </Winylo.Card>
      </div>

      <Winylo.Modal
        className={style.updateModal}
        isOpen={isModalUpdateOpen}
        onClose={() => setIsModalUpdateOpen(false)}
        closable
        title={"Mises à jour"}
      >
        <div style={{ maxHeight: "50vh", overflowY: "scroll" }}>
          {patchNotes.map((patchNote, key, array) => (
            <React.Fragment key={key}>
              <div key={key} style={{ display: "flex", flexDirection: "column" }}>
                {checkDate(key, array, patchNote) && (
                  <span style={{ fontWeight: 800, marginBottom: "0.5rem" }}>Mise à jour du {dateToString(patchNote.scheduledAt)}</span>
                )}
                <PatchnoteItem patchnote={patchNote} />
                <Winylo.Separator style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} color="gray" />
              </div>
            </React.Fragment>
          ))}
        </div>
      </Winylo.Modal>

      <Winylo.Modal
        isOpen={isModalMobileAppOpen}
        onClose={() => setIsModalMobileAppOpen(false)}
        closable
        title={"Télécharger l'application"}
        id="modal-download-app"
      >
        <div style={{ textAlign: "center" }}>
          <img
            src={`${process.env.PUBLIC_URL}/google-play-badge.png`}
            style={{ width: "66%", cursor: "pointer" }}
            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.winylo.capture", "_blank")}
          />
          <img
            src={`${process.env.PUBLIC_URL}/apple-store-badge.svg`}
            style={{ width: "66%", cursor: "pointer", marginTop: "0.25rem" }}
            onClick={() => window.open("https://apps.apple.com/bj/app/photos-vid%C3%A9os-winylo/id1589919257", "_blank")}
          />
          <p>Scannez le QR pour être redirigé vers le store et télécharger l'application</p>
          <div style={{ height: "200px" }}>
            <AwesomeQRCode
              options={{
                text: `${MY_DOMAIN}/redirect-to-store`,
                colorLight: "#ffffff",
                dotScale: 1,
                logoScale: 0.3,
                logoCornerRadius: 50,
                margin: 0,
              }}
            />
          </div>
        </div>
      </Winylo.Modal>
    </>
  );
}
