import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { Category, FilterType, ROLES, UserCategoryLevel, CategoryOption, Media, MediaV2, PageItem, Pagination, UserOption } from "../../api/_type";
import style from "./FolderView.module.css";
import Winylo from "@winylo/winylo-react-component";
import { dateStringToDate, dateToString, hasUserAccessCategory, MY_DOMAIN, stringToSize } from "../../utils/utils";
import { ReactComponent as ThreeDotsIcon } from "../../svg/three-dots.svg";
import { ReactComponent as FolderIcon } from "../../svg/folder.svg";
import { ReactComponent as SharedFolderIcon } from "../../svg/shared-folder.svg";
import { ReactComponent as PlayIcon } from "../../svg/play.svg";
import { useEffect, useState } from "react";
import PopOver from "../PopOver/PopOver";
import MediaModal from "../MediaModal/MediaModal";
import MoveMediaModal from "../MoveMediaModal/MoveMediaModal";
import { MediasContextType, useMedias } from "../../context/MediasContext";
import { useNavigate } from "react-router-dom";
import { UserContextType, useUser } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import ShareMediaModal from "../ShareMediaModal/ShareMediaModal";
import { DataUpdateFunction } from "react-query/types/core/utils";
import {
  faArrowPointer,
  faArrowRightArrowLeft,
  faArrowRotateLeft,
  faArrowRotateRight,
  faCamera,
  faCaretRight,
  faCheck,
  faFileImage,
  faFileVideo,
  faHand,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { faImage } from "@fortawesome/free-regular-svg-icons";

interface Props {
  categories?: Category[];
  category?: Category;
  setSelectedCategory: (category: Category) => void;
  searchValue?: string;
  setModalMediaOpen?: (isOpen: boolean) => void;
  createSubCategory?: (parent: Category) => void;
  setModalCategoryOpen?: (isOpen: boolean) => void;
  searchInput?: string;
  filter?: FilterType;
  selectedUsersFilterInput?: UserOption[];
  selectedCategoriesFilterInput?: CategoryOption[];
  setIsLoaderLoading?: (isDownloading: boolean) => void;
  setLoadingIconName?: (loadingIconName: string) => void;
  mediasTotalLength: number;
  setMediasTotalLength: React.Dispatch<React.SetStateAction<number>>;
}

export default function FolderView({ mediasTotalLength, setMediasTotalLength, ...props }: Props) {
  const [mediaOpened, setMediaOpened] = useState<MediaV2 | undefined>(undefined);
  const [mediaToEdit, setMediaToEdit] = useState<MediaV2 | undefined>(undefined);
  const [mediaToShare, setMediaToShare] = useState<MediaV2 | undefined>(undefined);
  const [mediasToMove, setMediasToMove] = useState<MediaV2[]>([]);
  const [mediasToDelete, setMediasToDelete] = useState<MediaV2[]>([]);
  const [mediaOptionsOpen, setMediaOptionsOpen] = useState<MediaV2 | undefined>(undefined);
  const [multipleMediasOptionsOpen, setMultipleMediasOptionsOpen] = useState<boolean>(false);

  const [user] = useUser() as UserContextType;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [medias, setMedias] = useMedias() as MediasContextType;

  const { hasNextPage, fetchNextPage, isFetching } = useInfiniteQuery(
    ["medias", props.category, props.filter],
    ({ pageParam = 1 }) => api.medias.getMediasByCategory({ category: props.category!.id, page: pageParam, sort: props.filter }),
    {
      onSuccess: (data) => {
        if (data === undefined || data.pages === undefined) {
          setMedias([]);
          setMediasTotalLength(0);
        } else {
          setMedias(data.pages.map((page) => page.items).flat());
          setMediasTotalLength(data.pages[0].pagination.totalCount);
        }
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      enabled: !!props.category,
      refetchInterval: 15 * 60 * 1000,
      refetchIntervalInBackground: true,
    }
  );

  const { mutate: rotateMedia, isLoading: isRotating } = useMutation(api.medias.updateMedia, {
    onSuccess: (result) => {
      setMediaOpened({ ...result, uri: result.uri });

      props.category
        ? queryClient.setQueryData(
            ["medias", props.category, props.filter],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;

              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.map((mediaV2) => {
                      return mediaV2.id === result.id ? result : mediaV2;
                    }),
                    pagination: page.pagination,
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          )
        : queryClient.setQueryData(
            ["filtered_medias", props.searchInput, props.filter, props.selectedUsersFilterInput, props.selectedCategoriesFilterInput],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;
              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.map((mediaV2) => {
                      return mediaV2.id === result.id ? result : mediaV2;
                    }),
                    pagination: page.pagination,
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          );
    },
  });

  const { mutate: deleteMedias, isLoading: isDeleting } = useMutation(api.medias.deleteMedias, {
    onMutate: () => {
      setSelectedMediaOptionsOpen(false);
      setIsSelecting(false);
      setSelectedMedias([]);

      props.setLoadingIconName && props.setLoadingIconName("trash-can-solid");
      props.setIsLoaderLoading && props.setIsLoaderLoading(true);
    },
    onSuccess: () => {
      props.category
        ? queryClient.setQueryData(
            ["medias", props.category, props.filter],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;

              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.filter((mediaV2) => !mediasToDelete.includes(mediaV2)),
                    pagination: { ...page.pagination, totalCount: page.pagination.totalCount - mediasToDelete.length },
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          )
        : queryClient.setQueryData(
            ["filtered_medias", props.searchInput, props.filter, props.selectedUsersFilterInput, props.selectedCategoriesFilterInput],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;

              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.filter((mediaV2) => !mediasToDelete.includes(mediaV2)),
                    pagination: { ...page.pagination, totalCount: page.pagination.totalCount - mediasToDelete.length },
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          );

      setMediasToDelete([]);
      props.setIsLoaderLoading && props.setIsLoaderLoading(false);
    },
    onError: (error) => {
      props.setIsLoaderLoading && props.setIsLoaderLoading(false);
      console.log(error);
    },
  });

  const { mutate: downloadMedias } = useMutation(api.medias.downloadMedias, {
    onMutate: () => {
      setMultipleMediasOptionsOpen(false);
      setIsSelecting(false);
      setSelectedMedias([]);

      props.setLoadingIconName && props.setLoadingIconName("download-icon");
      props.setIsLoaderLoading && props.setIsLoaderLoading(true);
    },
    onSuccess: (result) => {
      if (result) {
        var url = window.URL.createObjectURL(result.content);
        var a = document.createElement("a");
        a.href = url;
        a.download = result.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      props.setIsLoaderLoading && props.setIsLoaderLoading(false);
    },
    onError: (error) => {
      props.setIsLoaderLoading && props.setIsLoaderLoading(false);
      console.log(error);
    },
  });

  function getCategories(): Category[] | undefined {
    let res = props.category ? props.category.subCategories : props.categories;
    return res;
  }

  function renderEmptyFlex(count: number, className: string) {
    let elements = [];
    for (let i = 0; i < count; i++) {
      elements.push(<div className={className}></div>);
    }

    return elements;
  }

  function confirmMediaDelete() {
    if (mediasToDelete.length > 0) {
      deleteMedias({ mediasId: mediasToDelete.map((media) => media.id) });
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300) {
        if (hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching]);

  const [selectedMedias, setSelectedMedias] = useState<MediaV2[]>([]);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [selectedMediaOptionsOpen, setSelectedMediaOptionsOpen] = useState<boolean>(false);

  function handleMediaSelect(e: React.MouseEvent<HTMLDivElement, MouseEvent>, media: MediaV2) {
    if (!isSelecting) {
      e.preventDefault();
      return;
    }

    if (selectedMedias.find((selectedMedia) => selectedMedia.id === media.id)) {
      setSelectedMedias((o) => [...o.filter((oldMedia) => oldMedia.id !== media.id)]);
    } else {
      setSelectedMedias((o) => [...o, media]);
    }
  }

  function canEditSelectedMedias() {
    return !selectedMedias.some((media) => {
      return hasUserAccessCategory(user, media.category, UserCategoryLevel.LEVEL_EDIT) === false;
    });
  }

  useEffect(() => {
    setSelectedMedias([]);
  }, [props.category]);

  return (
    <>
      {getCategories() && <Winylo.ImportantNumber number={getCategories()!.length} text="Dossier" textPlural="Dossiers" />}
      {getCategories() !== undefined && getCategories()!.length > 0 ? (
        <div className={style.foldersContainer} id="folders-container">
          {getCategories()?.map((subCategory) => (
            <div key={`subcat_${subCategory.id}`} className={style.folder} onClick={() => props.setSelectedCategory(subCategory)}>
              {subCategory.sharedCategories.length > 0 ? (
                <SharedFolderIcon className={subCategory.isPrivate ? style.folderPrivate : style.folderPublic} />
              ) : (
                <FolderIcon className={subCategory.isPrivate ? style.folderPrivate : style.folderPublic} />
              )}
              <p>{subCategory.label}</p>
            </div>
          ))}
        </div>
      ) : user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) ? (
        getCategories() &&
        (props.category ? (
          <div className={style.empty} onClick={() => props.createSubCategory && props.category && props.createSubCategory(props.category)}>
            <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
            <span style={{ marginTop: "1rem" }}>Vous n'avez aucun sous-dossier.</span>
            <span>Cliquez ici pour en créer un !</span>
          </div>
        ) : (
          <div className={style.empty} onClick={() => props.setModalCategoryOpen && props.setModalCategoryOpen(true)}>
            <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
            <span style={{ marginTop: "1rem" }}>Vous n'avez aucun dossier.</span>
            <span>Cliquez ici pour en créer un !</span>
          </div>
        ))
      ) : (
        <></>
      )}
      {medias && (
        <div className={style.topBar}>
          <Winylo.ImportantNumber number={mediasTotalLength} text="Média" textPlural="Médias" />
          <div style={{ display: "flex", position: "relative", alignItems: "center", marginTop: "1rem" }}>
            {isSelecting && selectedMedias.length > 0 && (
              <span
                className={style.select}
                style={{ display: "flex", alignItems: "center" }}
                onClick={() => {
                  setSelectedMediaOptionsOpen(false);
                  setMultipleMediasOptionsOpen(true);
                  // setMediasToMove(selectedMedias);
                }}
              >
                {selectedMedias.length} élément{selectedMedias.length > 1 && "s"} séléctionné{selectedMedias.length > 1 && "s"}
                <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{ marginLeft: "0.375rem" }} />
                {multipleMediasOptionsOpen && selectedMedias.length > 0 && (
                  <PopOver className={style.popOver} clickOutside={() => setMultipleMediasOptionsOpen(false)}>
                    {canEditSelectedMedias() && (
                      <div
                        onClick={() => {
                          setMultipleMediasOptionsOpen(false);
                          setMediasToMove(selectedMedias);
                        }}
                      >
                        Déplacer / Dupliquer
                      </div>
                    )}
                    <div
                      onClick={() => {
                        downloadMedias({ mediasId: selectedMedias.map((selected) => selected.id) });
                      }}
                    >
                      Télécharger
                    </div>
                    {/* <div>Partager</div> */}
                    {/* <div>Dupliquer</div> */}

                    {canEditSelectedMedias() && (
                      <div
                        onClick={() => {
                          setMultipleMediasOptionsOpen(false);
                          setMediasToDelete(selectedMedias);
                        }}
                      >
                        Supprimer
                      </div>
                    )}
                  </PopOver>
                )}
              </span>
            )}
            <div
              className={classNames(style.select, isSelecting && style.isSelecting)}
              onClick={() => {
                isSelecting && setSelectedMedias([]);
                setIsSelecting((b) => !b);
              }}
            >
              <span style={{ marginRight: "0.5rem" }}>Sélectionner</span>
              <span style={{ width: "1rem", textAlign: "center" }}>
                <FontAwesomeIcon icon={isSelecting ? faHand : faArrowPointer} />
              </span>
            </div>

            {selectedMediaOptionsOpen && !mediaOpened && (
              <PopOver className={style.selectPopOver} clickOutside={() => setSelectedMediaOptionsOpen(false)}>
                <div
                  onClick={() => {
                    setSelectedMediaOptionsOpen(false);
                    setMediasToMove(selectedMedias);
                  }}
                >
                  Déplacer / Dupliquer
                </div>
              </PopOver>
            )}
          </div>
        </div>
      )}

      <div className={style.mediasContainer} id="medias-container">
        {medias && medias.length > 0
          ? medias.map((media) => (
              <div
                key={`fv_media_${media.id}`}
                className={classNames([
                  style.mediaContainer,
                  selectedMedias.find((selectedMedia) => selectedMedia.id === media.id) && style.mediaSelected,
                ])}
                onClick={(e) => handleMediaSelect(e, media)}
              >
                <div className={style.selectedCheck}>
                  <FontAwesomeIcon icon={faCheck} />
                </div>
                <div
                  onClick={() => !isSelecting && setMediaOpened(media)}
                  className={style.mediaImage}
                  style={{ backgroundImage: "url('" + media.uri_thumbnail + "')" /*, transform: `rotate(-${media.rotation}deg)` */ }}
                >
                  {media.type.includes("video") && (
                    <div className={style.videoIconContainer}>
                      <PlayIcon className={style.videoIcon} />
                    </div>
                  )}
                </div>
                <div className={style.mediaContent}>
                  <div className={style.mediaName}>
                    {media.name}
                    <FontAwesomeIcon
                      title={media.type.includes("video") ? "Vidéo" : "Image"}
                      className={style.typeIcon}
                      icon={media.type.includes("video") ? faVideo : faCamera}
                    />
                  </div>
                  {(props.searchValue || !props.category) && media.category?.label && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FolderIcon
                        className={classNames(style.mediaCategoryIcon, media.category.isPrivate ? style.folderPrivate : style.folderPublic)}
                      />
                      {media.category.superCategory && (
                        <>
                          <span className={style.mediaCategoryLabel}>{media.category.superCategory.label}</span>
                          <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: "0.25rem", marginLeft: "0.25rem" }} />
                        </>
                      )}
                      <span className={style.mediaCategoryLabel}>{media.category.label}</span>
                    </div>
                  )}
                  <div className={style.mediaDescription1}>{media.description ? media.description : " "}</div>
                  <div className={style.mediaBottom}>
                    <div className={style.mediaInfo}>
                      <div className={style.mediaCreator}>
                        {media.creator.firstname} {media.creator.lastname}
                      </div>
                      {dateToString(media.creationDate)} {stringToSize(media.weight)}
                    </div>
                    <div className={style.mediaDots} onClick={(e) => !isSelecting && setMediaOptionsOpen(media)}>
                      <ThreeDotsIcon width={"1rem"} height={"1rem"} />
                    </div>
                  </div>
                  {mediaOptionsOpen?.id === media.id && !mediaOpened && (
                    <PopOver className={style.popOver} clickOutside={() => setMediaOptionsOpen(undefined)}>
                      {hasUserAccessCategory(user, media.category, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setMediaOptionsOpen(undefined);
                            setMediasToMove([media]);
                          }}
                        >
                          Déplacer / Dupliquer
                        </div>
                      )}
                      <div
                        onClick={() => {
                          setMediaOptionsOpen(undefined);
                          setMediaToShare(media);
                        }}
                      >
                        Partager
                      </div>
                      <div
                        onClick={() => {
                          setMediaOptionsOpen(undefined);
                          downloadMedias({ mediasId: [media.id] });
                        }}
                      >
                        Télécharger
                      </div>
                      {hasUserAccessCategory(user, media.category, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setMediaOptionsOpen(undefined);
                            setMediaToEdit(media);
                          }}
                        >
                          Modifier
                        </div>
                      )}
                      {/* <div>Partager</div> */}
                      {/* <div>Dupliquer</div> */}
                      {hasUserAccessCategory(user, media.category, UserCategoryLevel.LEVEL_EDIT) && (
                        <div
                          onClick={() => {
                            setMediaOptionsOpen(undefined);
                            setMediasToDelete([media]);
                          }}
                        >
                          Supprimer
                        </div>
                      )}
                    </PopOver>
                  )}
                </div>
              </div>
            ))
          : medias &&
            (hasUserAccessCategory(user, props.category, UserCategoryLevel.LEVEL_ADD) ? (
              <div className={style.empty} onClick={() => props.setModalMediaOpen && props.setModalMediaOpen(true)}>
                <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
                <span style={{ marginTop: "1rem" }}>Vous n'avez aucun média.</span>
                {<span>Cliquez ici pour en créer un !</span>}
              </div>
            ) : (
              <div className={style.empty}>
                <div className={style.emptyIcon} style={{ backgroundImage: `url("${MY_DOMAIN}/empty.svg")` }} />
                <span style={{ marginTop: "1rem" }}>Ce dossier ne contient aucun média.</span>
              </div>
            ))}
        {renderEmptyFlex(4 - ((medias?.length || 0) % 4), style.mediaEmptyFlex)}
      </div>
      <Winylo.Modal title="Supprimer le média" isOpen={mediasToDelete.length > 0} onClose={() => setMediasToDelete([])}>
        <p>Voulez-vous vraiment supprimer {mediasToDelete.length > 1 ? "ces médias" : "ce média"} ?</p>
        <div className={style.modalDeleteButtons}>
          <Winylo.Button loading={isDeleting} variant="gray" fullWidth onClick={() => setMediasToDelete([])}>
            Annuler
          </Winylo.Button>
          <Winylo.Button loading={isDeleting} variant="red" fullWidth onClick={confirmMediaDelete}>
            Supprimer
          </Winylo.Button>
        </div>
      </Winylo.Modal>
      {mediaToEdit && (
        <MediaModal
          open={mediaToEdit ? true : false}
          onClose={() => {
            setMediaToEdit(undefined);
          }}
          media={mediaToEdit}
          category={props.category}
          searchInput={props.searchInput}
          filter={props.filter}
          selectedUsersFilterInput={props.selectedUsersFilterInput}
          selectedCategoriesFilterInput={props.selectedCategoriesFilterInput}
        />
      )}
      {mediasToMove.length > 0 && (
        <MoveMediaModal
          open={mediasToMove.length > 0}
          onClose={() => {
            setIsSelecting(false);
            setSelectedMedias([]);
            setMediasToMove([]);
          }}
          mediasToMove={mediasToMove}
          category={props.category}
          searchInput={props.searchInput}
          filter={props.filter}
          selectedUsersFilterInput={props.selectedUsersFilterInput}
          selectedCategoriesFilterInput={props.selectedCategoriesFilterInput}
        />
      )}
      {mediaToShare && (
        <ShareMediaModal
          open={mediaToShare ? true : false}
          onClose={() => {
            setMediaToShare(undefined);
          }}
          media={mediaToShare}
        />
      )}
      {mediaOpened && (
        <Winylo.Modal
          style={{ content: { maxHeight: "80vh", width: "100%", maxWidth: "30vw" } }}
          isOpen={true}
          onClose={() => setMediaOpened(undefined)}
        >
          <div>
            <div
              onClick={() => window.open(mediaOpened.uri, "_blank")}
              className={style.mediaImage}
              style={!mediaOpened.type.includes("video") ? { backgroundImage: "url('" + mediaOpened.uri + "')" } : {}}
            >
              {mediaOpened.type.includes("video") && (
                <div className={style.videoIconContainer}>
                  <video style={{ width: "100%" }} controls>
                    <source src={mediaOpened.uri} />
                  </video>
                </div>
              )}
            </div>
            <div className={style.mediaContent}>
              {mediaOpened.category && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FolderIcon
                    className={classNames(style.mediaCategoryIcon, mediaOpened.category.isPrivate ? style.folderPrivate : style.folderPublic)}
                  />
                  {mediaOpened.category.superCategory && (
                    <>
                      <span className={style.mediaCategoryLabel}>{mediaOpened.category.superCategory.label}</span>
                      <FontAwesomeIcon icon={faCaretRight} style={{ marginRight: "0.25rem", marginLeft: "0.25rem" }} />
                    </>
                  )}
                  <span className={style.mediaCategoryLabel}>{mediaOpened.category.label}</span>

                  <div
                    style={{
                      display: "flex",
                      fontSize: "2rem",
                      marginLeft: "auto",
                    }}
                  >
                    <FontAwesomeIcon
                      className={classNames(style.rotateIcon, isRotating && style.disabled)}
                      icon={faArrowRotateLeft}
                      onClick={() => !isRotating && mediaOpened && rotateMedia({ id: mediaOpened.id, rotation: (mediaOpened.rotation + 90) % 360 })}
                    />
                    <FontAwesomeIcon
                      className={classNames(style.rotateIcon, isRotating && style.disabled)}
                      style={{ marginLeft: "1rem" }}
                      icon={faArrowRotateRight}
                      onClick={() =>
                        !isRotating &&
                        mediaOpened &&
                        rotateMedia({ id: mediaOpened.id, rotation: ((mediaOpened.rotation != 0 ? mediaOpened.rotation : 360) - 90) % 360 })
                      }
                    />
                  </div>
                </div>
              )}
              <div className={style.mediaName}>{mediaOpened.name}</div>
              <div className={style.mediaDescription2} style={{ whiteSpace: "pre-line" }}>
                {mediaOpened.description}
              </div>
              <div className={style.mediaBottomModal}>
                <div className={style.mediaInfo}>{dateToString(mediaOpened.creationDate, true)}</div>
                <div className={style.mediaInfo}>{stringToSize(mediaOpened.weight)}</div>
                <div className={style.mediaInfo}>
                  Ajouté par {mediaOpened.creator.firstname} {mediaOpened.creator.lastname}
                </div>
              </div>
            </div>
          </div>
        </Winylo.Modal>
      )}
    </>
  );
}
