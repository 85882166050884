import qs from "qs";
import { URL_HOST, checkStatus, parseFormData } from "./api";
import { UserCategory } from "./_type";

const usercategories = {
  createUserCategory: ({ userId, categoryId, level }: { userId: number; categoryId: number; level: number }): Promise<UserCategory> => {
    return fetch(`${URL_HOST}/api/usercategories/create`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({ user: userId, category: categoryId, level }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  getUserCategoriesByCategory: (categoryId: number): Promise<UserCategory[]> => {
    return fetch(`${URL_HOST}/api/usercategories/category/${categoryId}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
        "content-type": "application/json",
      },
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  updateUserCategory: ({ userCategoryId, level }: { userCategoryId: number; level?: number }): Promise<UserCategory> => {
    return fetch(`${URL_HOST}/api/usercategories/${userCategoryId}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({ level: level || 0 }),
    })
      .then(checkStatus)
      .then((res) => res?.json());
  },
  deleteUserCategory: ({ userCategoryId }: { userCategoryId: number }): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/usercategories/${userCategoryId}`, {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
  toggleNotice: ({ userId, categoryId }: { userId: number; categoryId: number }): Promise<string | undefined> => {
    return fetch(`${URL_HOST}/api/usercategories/notice?${qs.stringify({ userId, categoryId })}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then(checkStatus)
      .then((res) => res?.text());
  },
};

export default usercategories;
