import Winylo from "@winylo/winylo-react-component";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import api from "../../api/api";
import { Category, CategoryOption, FilterType, MediaV2, PageItem, Pagination, UserOption } from "../../api/_type";

import { ReactComponent as FolderIcon } from "../../svg/folder.svg";
import { ReactComponent as OpenIcon } from "../../svg/angle-down-solid.svg";
import { ReactComponent as CloseIcon } from "../../svg/angle-up-solid.svg";

import style from "./MoveMediaModal.module.css";
import Checkbox from "../Checkbox/Checkbox";
import { CategoriesContextType, useCategories } from "../../context/CategoriesContext";

function Folder({
  category,
  multiSelect,
  onClickOnCategory,
  selectedCategories,
}: {
  selectedCategories: Category[];
  category: Category;
  multiSelect?: boolean;
  onClickOnCategory: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, selectedCategory: Category) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);

  function canBeOpen(): boolean {
    return category.subCategories && category.subCategories.length > 0 ? true : false;
  }

  function openClose(e: React.MouseEvent<any, MouseEvent>) {
    setOpen((b) => !b);
  }

  return (
    <div className={style.categoryContainer}>
      <div
        className={classNames(style.category, {
          [style.canBeOpen]: canBeOpen(),
        })}
      >
        <div>
          <div className={style.folderOptions}>
            <Checkbox
              className={style.checkbox}
              checked={selectedCategories.find((sc: Category) => sc.id === category.id) !== undefined}
              name="category_to_move"
              type={multiSelect ? "checkbox" : "radio"}
              click={(e: any) => onClickOnCategory(e, category)}
            />
          </div>
          <FolderIcon className={style.folderIcon} onClick={openClose} />
          <span className={style.label} onClick={openClose}>
            {category.label}
          </span>
        </div>
        {canBeOpen() && <div onClick={openClose}>{open ? <CloseIcon className={style.icon} /> : <OpenIcon className={style.icon} />}</div>}
      </div>

      <div className={style.subCategories} style={open ? {} : { display: "none" }}>
        {category.subCategories?.map((subCategory) => (
          <>
            <div className={style.subCategory}>
              <Checkbox
                className={style.checkbox}
                checked={selectedCategories.find((sc: Category) => sc.id === subCategory.id) !== undefined}
                name="category_to_move"
                type={multiSelect ? "checkbox" : "radio"}
                onClick={(e) => onClickOnCategory(e, subCategory)}
                label={
                  <div className={style.subCategoryCheckbox}>
                    <FolderIcon className={style.subCategoryIcon} />
                    <span className={style.label}>{subCategory.label}</span>
                  </div>
                }
              />
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  mediasToMove: MediaV2[];
  category?: Category;
  searchInput?: string;
  filter?: FilterType;
  selectedUsersFilterInput?: UserOption[];
  selectedCategoriesFilterInput?: CategoryOption[];
}

export default function MoveMediaModal(props: Props) {
  const queryClient = useQueryClient();

  const [categories] = useCategories() as CategoriesContextType;

  const [selectedCategories, setSelectedCategories] = useState<Category[]>([]);
  const [toClone, setToClone] = useState<boolean>(false);

  function onClose() {
    if (props.onClose !== undefined) props.onClose();
  }

  function handleMoveMedia() {
    const ids = selectedCategories.map(({ id }) => id);

    if (selectedCategories.length > 0) {
      if (toClone) {
        props.mediasToMove.forEach((media) => {
          cloneMedia({ mediaId: media.id, categoriesId: ids });
        });
      } else {
        moveMedias({ mediasId: props.mediasToMove.map((m) => m.id), categoryId: ids[0] });
      }
    }
  }

  const { mutate: moveMedias, isLoading: isMoving } = useMutation(api.medias.moveMedias, {
    onSuccess: () => {
      props.category
        ? selectedCategories[0].id !== props.category?.id &&
          queryClient.setQueryData(
            ["medias", props.category, props.filter],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;

              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.filter((mediaV2) => !props.mediasToMove.includes(mediaV2)),
                    pagination: { ...page.pagination, totalCount: page.pagination.totalCount - props.mediasToMove.length },
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          )
        : queryClient.setQueryData(
            ["filtered_medias", props.searchInput, props.filter, props.selectedUsersFilterInput, props.selectedCategoriesFilterInput],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;
              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.map((mediaV2) => {
                      return !props.mediasToMove.includes(mediaV2) ? mediaV2 : { ...mediaV2, category: { ...selectedCategories[0] } };
                    }),
                    pagination: page.pagination,
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          );

      onClose();
    },
  });

  const { mutate: cloneMedia, isLoading: isCloning } = useMutation(api.medias.cloneMedia, {
    onSuccess: (result) => {
      props.category
        ? selectedCategories.some(({ id }) => id === props.category?.id) &&
          queryClient.setQueryData(
            ["medias", props.category, props.filter],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;
              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  if (page.pagination.current === 1) {
                    page.items.unshift(result);
                    return { items: page.items, pagination: { ...page.pagination, totalCount: page.pagination.totalCount + 1 } };
                  }

                  return { ...page };
                }),
                pageParams: old.pageParams,
              };
            }
          )
        : queryClient.setQueryData(
            ["filtered_medias", props.searchInput, props.filter, props.selectedUsersFilterInput, props.selectedCategoriesFilterInput],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;
              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  if (page.pagination.current === 1) {
                    page.items.unshift(result);
                    return { items: page.items, pagination: { ...page.pagination, totalCount: page.pagination.totalCount + 1 } };
                  }

                  return { ...page };
                }),
                pageParams: old.pageParams,
              };
            }
          );

      onClose();
    },
  });

  function checkboxChange() {
    setToClone(!toClone);
    setSelectedCategories(selectedCategories.length > 0 ? [selectedCategories[0]] : []);
  }

  function handleClickOnCatagory(e: any, category: Category) {
    if (toClone) {
      selectedCategories.includes(category)
        ? setSelectedCategories(selectedCategories.filter((v) => v !== category))
        : setSelectedCategories((old) => [...old, category]);
    } else {
      setSelectedCategories(Array(category));
    }
  }

  return (
    <Winylo.Modal isOpen={props.open} onClose={onClose} title={`Déplacer / Dupliquer ${props.mediasToMove.length > 1 ? "les médias" : "le média"}`}>
      <div className={style.content}>
        {categories
          ?.filter((category) => category.superCategory === null)
          .map((category) => (
            <Folder
              selectedCategories={selectedCategories}
              category={category}
              multiSelect={toClone}
              onClickOnCategory={(e: any, category: Category) => handleClickOnCatagory(e, category)}
            />
          ))}
      </div>
      <div className={style.footer}>
        <div style={{ width: "50%" }}>
          <Winylo.Checkbox className={style.clone} label={"Dupliquer ?"} checked={toClone} onChange={checkboxChange}></Winylo.Checkbox>
        </div>

        <Winylo.Button variant="blue" fullWidth onClick={handleMoveMedia} disabled={selectedCategories.length <= 0 || isCloning || isMoving}>
          {`${toClone ? "Dupliquer" : "Déplacer"} ${props.mediasToMove.length > 1 ? "les médias" : "le média"}`}
        </Winylo.Button>
      </div>
    </Winylo.Modal>
  );
}
