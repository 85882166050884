import Winylo from "@winylo/winylo-react-component";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import * as Yup from "yup";
import api from "../../api/api";
import { Category, CategoryOption, FilterType, MediaV2, PageItem, Pagination, UserOption } from "../../api/_type";
import { toFileArray } from "../../utils/utils";
import ModalExtanded from "../../winylo/components/ModalExtanded";
import Carousel from "../Carousel/Carousel";

import style from "./MediaModal.module.css";
import Dropzone from "react-dropzone";

interface Props {
  open: boolean;
  onClose: () => void;
  media?: MediaV2;
  category?: Category;
  searchInput?: string;
  filter?: FilterType;
  selectedUsersFilterInput?: UserOption[];
  selectedCategoriesFilterInput?: CategoryOption[];
}

export default function MediaModal(props: Props) {
  const [files, setFiles] = useState<File[]>([]);

  const create = props.media === undefined;
  const descriptionMaxLength = 700;

  const queryClient = useQueryClient();

  const previewRef = useRef<any>(null);

  const { mutate: createMedia, isLoading: isCreating } = useMutation(api.medias.addMedia, {
    onSuccess: (result) => {
      queryClient.setQueriesData(
        ["medias", props.category, props.filter],
        (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
          if (!old) return old as any;

          return {
            pages: old.pages.map((page: PageItem<MediaV2>) => {
              if (page.pagination.current <= 1) {
                page.items.unshift(result);

                return { items: page.items, pagination: { ...page.pagination, totalCount: page.pagination.totalCount + 1 } };
              }

              return { ...page };
            }),
            pageParams: old.pageParams,
          };
        }
      );

      onClose();
    },
  });

  const {
    mutate: updateMedia,
    isLoading: isUpdating,
    variables,
  } = useMutation(api.medias.updateMedia, {
    onSuccess: (result) => {
      props.category
        ? queryClient.setQueryData(
            ["medias", props.category, props.filter],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;

              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.map((mediaV2) => {
                      return mediaV2.id === result.id ? result : mediaV2;
                    }),
                    pagination: page.pagination,
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          )
        : queryClient.setQueryData(
            ["filtered_medias", props.searchInput, props.filter, props.selectedUsersFilterInput, props.selectedCategoriesFilterInput],
            (old: { pages: { items: MediaV2[]; pagination: Pagination }[]; pageParams: any } | undefined) => {
              if (!old) return old as any;
              return {
                pages: old.pages.map((page: PageItem<MediaV2>) => {
                  return {
                    items: page.items.map((mediaV2) => {
                      return mediaV2.id === result.id ? result : mediaV2;
                    }),
                    pagination: page.pagination,
                  };
                }),
                pageParams: old.pageParams,
              };
            }
          );

      onClose();
    },
  });

  const initialValues: { name: string; description?: string; files?: File[] } = {
    name: "",
    description: "",
    files: [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("Le nom du média ne peut pas être vide.").max(255, "Le nom ne peut pas excéder 255 caractères."),
      description: Yup.string().max(descriptionMaxLength, `La description ne peut pas excéder ${descriptionMaxLength} caractères.`),
      files: props.media ? Yup.mixed() : Yup.mixed().required(),
    }),
    onSubmit: (values) => {
      if (create) {
        if (values.files && values.files.length > 0) {
          let name = values.name;
          for (let i = 0; i < values.files.length; i++) {
            if (props.category) {
              let body = {
                ...values,
                name: name,
                file: values.files[i],
                categoryId: props.category.id,
              };
              createMedia(body);

              name = `${values.name} (${i + 1})`;
            }
          }
        }
      } else {
        if (props.media?.id !== undefined) updateMedia({ id: props.media?.id, ...values });
      }
    },
  });

  function handleFileChange(mfiles: File[]) {
    formik.setFieldValue("files", formik.values.files ? [...formik.values.files, ...mfiles] : mfiles);
    setFiles((o) => [...o, ...mfiles]);
  }

  function onClose() {
    formik.resetForm();
    setFiles([]);
    if (props.onClose !== undefined) props.onClose();
  }

  useEffect(() => {
    if (props.media) {
      formik.resetForm({
        values: {
          name: props.media.name,
          description: props.media.description ? props.media.description : undefined,
          files: [],
        },
      });
    } else {
      formik.resetForm({
        values: initialValues,
      });
    }
    setFiles([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.media]);

  return (
    <ModalExtanded
      modalStyle={{ content: { width: "40vw", maxWidth: "50rem" } }}
      isOpen={props.open}
      onClose={onClose}
      title={<>{props.media === undefined ? "Ajouter des médias" : "Modifier le média"}</>}
    >
      <form style={{ height: "100%" }} onSubmit={formik.handleSubmit}>
        <span id="media-modal">
          <Winylo.Input
            autoComplete="off"
            id="name"
            name="name"
            variant={formik.touched.name && formik.errors.name ? "invalid" : "normal"}
            message={formik.touched.name && formik.errors.name ? formik.errors.name : undefined}
            label="Nom / Référence"
            placeholder="Exemple: référence produit"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            icon={() => <svg></svg>}
          ></Winylo.Input>
          <Winylo.Textarea
            id="description"
            name="description"
            variant={formik.touched.description && formik.errors.description ? "invalid" : "normal"}
            message={formik.touched.description && formik.errors.description ? formik.errors.description : undefined}
            label="Description"
            placeholder="Exemple: vue de face"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
            rows={5}
            maxLength={descriptionMaxLength}
          ></Winylo.Textarea>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <span style={{ fontSize: "0.75rem" }}>{`${formik.values.description?.length || 0} / ${descriptionMaxLength} `}</span>
          </div>
          {props.media === undefined && (
            <>
              <Dropzone
                onDrop={handleFileChange}
                accept={{ image: ["image/jpeg", "image/png" /*, "image/gif"*/], video: ["video/mp4", "video/quicktime"] }}
              >
                {({ getRootProps, getInputProps }) => (
                  <>
                    <Winylo.InputFile
                      label="Fichiers"
                      id="file"
                      name="file"
                      accept="image/png, image/jpeg, video/mp4, video/quicktime" // gif non supportés pour le moment
                      multiple
                      onChange={(e) => handleFileChange(toFileArray(e.currentTarget.files))}
                    />
                    <div {...getRootProps()} className={classNames(style.filePreview, style.empty)}>
                      <input {...getInputProps()} />
                      {<Carousel previewText="Sélectionnez ou déposez vos médias ici" files={files}></Carousel>}
                    </div>
                  </>
                )}
              </Dropzone>
            </>
          )}
        </span>
        <div style={{ textAlign: "center" }}>
          <Winylo.Button type="submit" style={{ marginTop: "1.875rem" }} disabled={isCreating || isUpdating} id="create-media-in-modal">
            {props.media === undefined ? "Ajouter des médias" : "Modifier le média"}
          </Winylo.Button>
        </div>
      </form>
    </ModalExtanded>
  );
}
