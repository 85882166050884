import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { ReactElement } from "react";
import ReactModal from "react-modal";

import style from "../css/ModalExtanded.module.css";

import { ReactComponent as Xmark } from "../svg/xmark-solid.svg";

interface Props {
  children: React.ReactNode;
  isOpen: boolean;
  title?: ReactElement;
  titlePosition?: "left" | "center";
  onClose?: () => void;
  closable?: boolean;
  modalStyle?: ReactModal.Styles;
  childrenContainerStyle?: React.CSSProperties;
  className?: string | ReactModal.Classes | undefined;
  id?: string;
}

export default function ModalExtanded(props: Props) {
  return (
    <ReactModal
      id={props.id}
      className={style.modal}
      overlayClassName={style.overlay}
      isOpen={props.isOpen}
      onRequestClose={props.closable !== false ? props.onClose : undefined}
      style={props.modalStyle}
      ariaHideApp={false}
    >
      {props.title && (
        <>
          <div
            className={classNames(
              style.titleContainer,
              props.titlePosition === undefined || props.titlePosition === "center" ? style.titleCenter : style.titleLeft
            )}
          >
            <div></div>
            <div className={style.title}>{props.title}</div>
            {props.closable !== false && <FontAwesomeIcon icon={faCircleXmark} className={style.close} onClick={props.onClose} />}
          </div>
          <div className={style.separator}></div>
        </>
      )}
      <div style={props.childrenContainerStyle}>{props.children}</div>
    </ReactModal>
  );
}
