import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import style from "../css/TopBar.module.css";

import { ReactComponent as UserCircle } from "../svg/user-circle.svg";
import { ReactComponent as CaretDown } from "../svg/caret-down.svg";
import { ReactComponent as PowerOff } from "../svg/power-off.svg";
import { ReactComponent as OnboardingCircle } from "../../svg/onboarding-circle.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { UserContextType, useUser } from "../../context/UserContext";
import { ROLES } from "../../api/_type";
import { isAdmin } from "../../utils/utils";

interface Action {
  text: string;
  onClick: () => void;
}

interface Props {
  username?: string;
  userRole?: string;
  logout?: () => void;
  actions?: Action[];
  brandLogoSrc: string;
  brandLogoClick?: () => void;
  brandLogoText?: string;
}

export default function TopBar(props: Props) {
  const navigate = useNavigate();

  const menu = useRef<any>(null);
  const menuButton = useRef<any>(null);
  const onboarding = useRef<any>(null);
  const onboardingButton = useRef<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState<boolean>(false);

  const [user] = useUser() as UserContextType;

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target) && menuButton.current && !menuButton.current.contains(e.target)) {
      toggleMenu();
    }
    if (onboarding.current && !onboarding.current.contains(e.target) && onboardingButton.current && !onboardingButton.current.contains(e.target)) {
      toggleOnboarding();
    }
  };

  function toggleMenu() {
    setIsMenuOpen((before) => !before);
  }

  function toggleOnboarding() {
    setIsOnboardingOpen((before) => !before);
  }

  function startOnBoardingTour(index: number) {
    toggleOnboarding();
    navigate(`/?onBoardingStart=${index}`);
  }

  return (
    <>
      <div className={style.topbar}>
        <div className={style.brandLogo} onClick={props.brandLogoClick}>
          <img className={style.brandLogoIcon} src={props.brandLogoSrc} alt="Logo" />
          <span className={style.brandLogoText}>{props.brandLogoText}</span>
        </div>
        <div className={style.rightItems}>
          {isAdmin(user) && (
            <div className={style.onboarding} ref={onboardingButton} onClick={toggleOnboarding}>
              <OnboardingCircle className={classNames(style.icon, style.circle)} />
            </div>
          )}
          <div className={style.userInfoContainer}>
            <span className={style.userName}>{props.username}</span>
            <span className={style.userRole}>{props.userRole}</span>
          </div>
          <div className={style.collapseButton} ref={menuButton} onClick={toggleMenu}>
            <UserCircle className={classNames(style.icon, style.userCircle)} />
            <CaretDown
              className={classNames(style.icon, style.caretDown, {
                [style.reverseCaretDown]: isMenuOpen === true,
              })}
            />
          </div>
          {isMenuOpen && (
            <div className={style.menu} ref={menu}>
              {props.actions?.map((action) => (
                <p onClick={action.onClick}>{action.text}</p>
              ))}
              {/* <div className={style.separator}></div> */}
              {props.logout && (
                <p className={style.disconnect} onClick={props.logout}>
                  <PowerOff />
                  Déconnexion
                </p>
              )}
            </div>
          )}
          {isOnboardingOpen && (
            <div className={classNames([style.onboardingMenu])} ref={onboarding}>
              <span className={style.textContent}>Découvrez les tutoriels</span>
              <div className={style.itemList}>
                {user?.roles.includes(ROLES.ROLE_COMPANY_ADMIN) && user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN) && (
                  <div className={style.listItem} onClick={() => startOnBoardingTour(0)}>
                    <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                    Créer et modifier un dossier
                  </div>
                )}
                <div className={style.listItem} onClick={() => startOnBoardingTour(1)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Ajouter un média depuis l'ordinateur
                </div>
                <div className={style.listItem} onClick={() => startOnBoardingTour(2)}>
                  <FontAwesomeIcon icon={faCheck} className={style.faIcon} />
                  Ajouter un média depuis l'application mobile
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={style.fakeTopbar}></div>
    </>
  );
}
