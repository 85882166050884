import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components, DropdownIndicatorProps, GroupBase, NoticeProps, OptionsOrGroups } from "react-select";
import { StateManagerProps } from "react-select/dist/declarations/src/useStateManager";

import "./StyledSelect.css";

interface Props extends StateManagerProps {}

export default function StyledSelect(props: Props) {
  const DropdownIndicator = (props: DropdownIndicatorProps<unknown, true>) => {
    return (
      <components.DropdownIndicator {...props}>
        <FontAwesomeIcon icon={faCaretDown} color="black" />
      </components.DropdownIndicator>
    );
  };

  const NoOptionsMessage = (props: NoticeProps) => {
    return <components.NoOptionsMessage {...props} />;
  };

  return (
    <Select
      placeholder="Sélectionner..."
      // noOptionsMessage={{ NoOptionsMessage } as any}
      {...props}
      classNamePrefix={"react-styled-select"}
      components={{ DropdownIndicator, NoOptionsMessage, ...props.components } as any}
    />
  );
}
