import style from "./PopOver.module.css";
import classNames from "classnames";
import { useEffect, useRef } from "react";

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  clickOutside: () => any;
  hideUglyStyle?: boolean;
}

export default function PopOver(props: Props) {
  const menu = useRef<any>(null);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick, false);
    document.addEventListener("contextmenu", handleOutsideClick, false);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("contextmenu", handleOutsideClick);
    };
  });

  const handleOutsideClick = (e: any) => {
    if (menu.current && !menu.current.contains(e.target)) {
      props.clickOutside();
    }
  };

  return (
    <div ref={menu} {...props} className={classNames(props.hideUglyStyle ? "" : style.container, props.className)}>
      {props.children}
    </div>
  );
}
