export interface Company {
  id: number;
  name: string;
  properties?: CompanyProperties;
}

export interface CompanyProperties {
  access?: {
    capture?: number;
    kaizen?: number;
    catalogue?: number;
  };
}

export enum VIDEO_QUALITY {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export const VideoQuality: { [key: string]: string } = {
  [VIDEO_QUALITY.LOW]: "Basse",
  [VIDEO_QUALITY.MEDIUM]: "Moyenne",
  [VIDEO_QUALITY.HIGH]: "Haute",
};

export interface BigCompany extends Company {
  maxSizePhoto: number;
  maxSizeVideo: number;
  shelfLife: number;
  maxUploadSize: number;
  photoCompression: number;
  videoQuality: VIDEO_QUALITY;
}

export enum ROLES {
  ROLE_ADMIN = "ROLE_ADMIN",
  ROLE_COMPANY_ADMIN = "ROLE_COMPANY_ADMIN",
  ROLE_USER = "ROLE_USER",
  ROLE_APPLICATION_ADMIN = "ROLE_APPLICATION_ADMIN",
  ROLE_APPLICATION_ACCESS = "ROLE_APPLICATION_ACCESS",
}

export enum UserCategoryLevel {
  LEVEL_NONE = 0,
  LEVEL_READ = 1,
  LEVEL_ADD = 2,
  LEVEL_EDIT = 3,
}

export interface User {
  id: number;
  firstname: string;
  lastname: string;
  applicationRoles: string[];
  email: string;
  roles: ROLES[];
  company: Company;
  userCategories?: UserCategory[];
}

export interface MediaLittle {
  id: number;
  type: string;
  weight: number;
  uri: string;
  uri_thumbnail: string;
}

export interface Capture {
  [index: string]: string | Date | number | User | MediaLittle | Category | undefined;
  id: 0;
  creationDate: string | Date;
  modificationDate: string | Date;
  label: string;
  creator: User;
  lastMedia?: MediaLittle;
  category: Category;
}

export interface Category {
  id: number;
  label: string;
  sharedCategories: SharedCategory[];
  subCategories?: Category[];
  superCategory?: Category | null;
  photoCompression: number;
  videoQuality: VIDEO_QUALITY;
  isPrivate: boolean;
  isLocked: boolean;
}

export interface SharedCategory {
  id: number;
  authorizedApp: SmallAuthorizedApp;
}

export interface Media {
  id: number;
  type: string;
  weight: number;
  creationDate: string | Date;
  capture: Capture;
  creator: User;
  file: string;
  uri: string;
  uri_thumbnail: string;
}

export interface Media {
  id: number;
  type: string;
  weight: number;
  creationDate: string | Date;
  capture: Capture;
  creator: User;
  file: string;
  uri: string;
  uri_thumbnail: string;
}

export interface MediaV2 {
  id: number;
  type: string;
  weight: number;
  creationDate: string | Date;
  creator: User;
  file: string;
  uri: string;
  uri_thumbnail: string;
  uri_public?: string;
  category: Category;
  name: string;
  description: string | null;
  isPublic?: boolean;
  rotation: number;
}
export interface DownloadFile {
  name: string;
  content: Blob;
}

export interface SmallAuthorizedApp {
  id: number;
  name: string;
}

export interface AuthorizedApp extends SmallAuthorizedApp {
  token: string;
  hasAccess: true;
}

export interface Stats {
  name: string;
  value: number | string;
}

export interface StorageData {
  available_storage: number;
  used_storage: number;
  remaining_storage: number;
}

export interface Pagination {
  last: number;
  current: number;
  numItemsPerPage: number;
  first: number;
  pageCount: number;
  totalCount: number;
  pageRange: number;
  startPage: number;
  endPage: number;
  pagesInRange: number[];
  firstPageInRange: number;
  lastPageInRange: number;
  currentItemCount: number;
  firstItemNumber: number;
  lastItemNumber: number;
}

export interface PageItem<T> {
  pagination: Pagination;
  items: T[];
}

export enum FilterType {
  DATE_RECENT = "DATE_RECENT",
  DATE_OLD = "DATE_OLD",
  ALPHA_AZ = "ALPHA_AZ",
  ALPHA_ZA = "ALPHA_ZA",
}

export interface CategoryOption {
  value: number;
  label: string;
  isSub?: boolean;
}

export interface SmallUser {
  id: number;
  media: Media[];
  firstname: string;
  lastname: string;
  userCategories?: UserCategory[];
}

export interface UserOption {
  value: number;
  label: string;
  color?: string;
  rightsLevel?: AccessType;
}

export interface UserCategory {
  id: number;
  category: {
    id: number;
    label: string;
  };
  user: {
    id: number;
    firstname: string;
    lastname: string;
  };
  level: AccessType;
  isNoticed: boolean;
}

export const UserOptionColors = [
  "#bde0ff",
  "#ffddbd",
  "#e4f1cb",
  "#dbbdff",
  "#bdf1ff",
  "#ffbdfd",
  "#bdf7ff",
  "#c4f8ef",
  "#bde0ff",
  "#ffbdd6",
  "#c8f4d5",
  "#bde0ff",
  "#ffbdfd",
];

export enum AccessType {
  NONE = 0,
  READ = 1,
  WRITE = 2,
  UPDATE = 3,
}

export interface SmallApplication extends SmallAuthorizedApp {
  fileUri: string | null;
}

export interface Patchnote {
  id: number;
  title: string;
  content: string;
  type: string;
  application: SmallApplication;
  scheduledAt: string;
}

export interface Tag {
  label: string;
  color: string;
}
