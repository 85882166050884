import { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Select, { MultiValueGenericProps } from "react-select";
import { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import classNames from "classnames";

// Style
import style from "./MainView.module.css";

// API
import api from "../../api/api";
import { Category, CategoryOption, FilterType, ROLES, UserCategoryLevel, UserOption, UserOptionColors } from "../../api/_type";

// Context
import { CategoriesContextType, useCategories } from "../../context/CategoriesContext";
import { MediasContextType, useMedias } from "../../context/MediasContext";
import { UserContextType, useUser } from "../../context/UserContext";

// Utils
import { hasUserAccessCategory, MY_DOMAIN, stringToSize, useDebounce } from "../../utils/utils";
import { APP_TITLE } from "../../utils/constantes";
import { OnboradingEvents } from "../../utils/onboarding/events.onboarding";

// @Winylo
import Winylo from "@winylo/winylo-react-component";
import "@winylo/winylo-react-component/dist/css/index.css";
import Loader from "../../winylo/components/Loader";

// Composants
import FolderList from "../../components/FolderList/FolderList";
import CategoryModal from "../../components/CategoryModal/CategoryModal";
import StyledSelect from "../../components/StyledSelect/StyledSelect";
import FolderView from "../../components/FolderView/FolderView";
import MediaModal from "../../components/MediaModal/MediaModal";
import ConnectFolderModal from "../../components/ConnectFolderModal/ConnectFolderModal";
import PopOver from "../../components/PopOver/PopOver";

// @Fortawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faUser } from "@fortawesome/free-solid-svg-icons";

// SVG
import { ReactComponent as FilterIcon } from "../../svg/filter-solid.svg";
import { ReactComponent as SlidersIcon } from "../../svg/sliders-solid.svg";

enum SearchType {
  ALL = "all",
  MEDIA = "media",
  FOLDER = "folder",
}

function MainView() {
  let { folderId } = useParams();

  const searchOptions = [
    {
      value: SearchType.ALL,
      label: "Tout",
    },
    {
      value: SearchType.FOLDER,
      label: "Dossier",
    },
    {
      value: SearchType.MEDIA,
      label: "Média",
    },
  ];

  const filterOptions = [
    {
      value: FilterType.DATE_RECENT,
      label: "Création (récent)",
    },
    {
      value: FilterType.DATE_OLD,
      label: "Création (ancien)",
    },
    {
      value: FilterType.ALPHA_AZ,
      label: "A à Z",
    },
    {
      value: FilterType.ALPHA_ZA,
      label: "Z à A",
    },
  ];

  const [editedCategory, setEditedCategory] = useState<Category | undefined>(undefined);
  const [connectedCategory, setConnectedCategory] = useState<Category | undefined>(undefined);
  const [parentCategory, setParentCategory] = useState<Category | undefined>(undefined);
  const [selectedCategory, setSelectedCategory] = useState<Category | undefined>(undefined);

  const [modalCategoryOpen, setModalCategoryOpen] = useState<boolean>(false);
  const [modalMediaOpen, setModalMediaOpen] = useState<boolean>(false);
  const [categoryToDelete, setCategoryToDelete] = useState<Category | undefined>(undefined);

  const [searchType, setSearchType] = useState<SearchType>(SearchType.ALL);
  const [filterType, setFilterType] = useState<FilterType>(FilterType.DATE_RECENT);
  const [searchInput, setSearchInput] = useState<string>("");

  const queryClient = useQueryClient();

  const [, setMedias] = useMedias() as MediasContextType;
  const [mediasTotalLength, setMediasTotalLength] = useState<number>(0);

  const [categories, setCategories] = useCategories() as CategoriesContextType;
  const [filteredCategories, setFilteredCategories] = useState<Category[] | undefined>(undefined);

  const [isLoaderLoading, setIsLoaderLoading] = useState<boolean>(false);
  const [loadingIconName, setLoadingIconName] = useState<string>("download-icon");

  const [user] = useUser() as UserContextType;

  const [isSlidersOpen, setIsSlidersOpen] = useState<boolean>(false);
  const [loaderState, setLoaderState] = useState<boolean>(false);

  const [selectedCategoriesFilterInput, setSelectedCategoriesFilterInput] = useState<CategoryOption[]>([]);
  const [selectedCategoriesFilter, setSelectedCategoriesFilter] = useDebounce<CategoryOption[]>(
    [],
    (selectedCategories) => {
      setSelectedCategoriesFilterInput(selectedCategories);
    },
    500
  );

  const [selectedUsersFilterInput, setSelectedUsersFilterInput] = useState<UserOption[]>([]);
  const [selectedUsersFilter, setSelectedUsersFilter] = useDebounce<UserOption[]>(
    [],
    (selectedUsers) => {
      setSelectedUsersFilterInput(selectedUsers);
    },
    500
  );

  const [searchValue, setSearchValue] = useDebounce<any>(
    "",
    (searchValue) => {
      setSearchInput(searchValue);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    500
  );

  useEffect(() => {
    if (searchInput.length === 0 && selectedUsersFilterInput.length === 0 && selectedCategoriesFilterInput.length === 0) {
      setFilteredCategories(categories);
      setMedias([]);
      setMediasTotalLength(0);
      queryClient.invalidateQueries("medias");
    } else {
      refetchMedias();
    }
  }, [searchInput, selectedUsersFilterInput, selectedCategoriesFilterInput]);

  function changeSelectedCategory(category?: Category) {
    setSearchValue("");
    setSelectedUsersFilter([]);
    setSelectedCategoriesFilter([]);
    setSelectedCategory(category);
    setSearchType(SearchType.ALL);
    setIsSlidersOpen(false);
  }

  useQuery("categories", api.categories.getCategories, {
    onSuccess: (data) => {
      setCategories(data);
      if (searchValue.length === 0) {
        setFilteredCategories(data);
      }
      let flattenCategories = data.map((cat) => [cat, cat.subCategories]).flat(2);
      if (folderId) {
        setSelectedCategory(flattenCategories?.find((cat) => cat?.id === parseInt(folderId || "")) || undefined);
      }
    },
  });

  const {
    refetch: refetchMedias,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useInfiniteQuery(
    ["filtered_medias", searchInput, filterType, selectedUsersFilterInput, selectedCategoriesFilterInput],
    ({ pageParam = 1 }) => {
      return api.medias.getMediasByFilter({
        page: pageParam,
        global: searchInput,
        sort: filterType,
        authors: selectedUsersFilterInput.map((u) => u.value),
        folders: selectedCategoriesFilterInput.map((f) => f.value),
      });
    },
    {
      onSuccess: (data) => {
        searchType === SearchType.MEDIA && setFilteredCategories(undefined);
        if (data === undefined || data.pages === undefined) {
          setMedias([]);
          setMediasTotalLength(0);
        } else {
          setMedias(data.pages.map((page) => page.items).flat());
          setMediasTotalLength(data.pages[0].pagination.totalCount);
        }
      },
      getNextPageParam: (lastPage, pages) => {
        if (window.location.href.match(/\/folder\/\d+/g) === null) {
          if (lastPage.pagination.current < lastPage.pagination.endPage) {
            return lastPage.pagination.current + 1;
          } else {
            return undefined;
          }
        }
      },
      keepPreviousData: true,
      enabled:
        (searchType === SearchType.MEDIA || searchType === SearchType.ALL) &&
        (searchInput.length > 0 || selectedUsersFilterInput.length > 0 || selectedCategoriesFilterInput.length > 0),
    }
  );

  const {
    isLoading: isLoading2,
    hasNextPage: hasNextPage2,
    fetchNextPage: fetchNextPage2,
    isFetching: isFetching2,
  } = useInfiniteQuery(
    ["filtered_categories", searchInput, filterType, selectedUsersFilterInput, selectedCategoriesFilterInput],
    ({ pageParam = 1 }) =>
      api.categories.getCategoriesByFilter({ page: pageParam, global: searchInput, folders: selectedCategoriesFilterInput.map((f) => f.value) }),
    {
      onSuccess: (data) => {
        if (searchType === SearchType.FOLDER) {
          setMedias([]);
          setMediasTotalLength(0);
        }

        if (data === undefined || data.pages === undefined) {
          setFilteredCategories([]);
        } else {
          setFilteredCategories(data.pages.map((page) => page.items).flat());
        }
      },
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.pagination.current < lastPage.pagination.endPage) {
          return lastPage.pagination.current + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
      enabled:
        (searchType === SearchType.FOLDER || searchType === SearchType.ALL) &&
        (searchInput.length > 0 || selectedUsersFilterInput.length > 0 || selectedCategoriesFilterInput.length > 0),
    }
  );

  const { data: companyUsers } = useQuery("company_users", api.companies.getCompanyUsers);

  const { data: storageData } = useQuery("storage_data", api.companies.getStorageData);

  const { mutate: deleteCategoryRequest } = useMutation(api.categories.deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
      queryClient.invalidateQueries("filtered_categories");
    },
  });

  const { mutate: downloadCategoryMedias } = useMutation(api.categories.downloadMedias, {
    onMutate: () => {
      setLoadingIconName("download-icon");
      setIsLoaderLoading(true);
    },
    onSuccess: (result) => {
      if (result) {
        var url = window.URL.createObjectURL(result.content);
        var a = document.createElement("a");
        a.href = url;
        a.download = result.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      setIsLoaderLoading(false);
    },
    onError: (error) => {
      setIsLoaderLoading(false);
      console.log(error);
    },
  });

  function editCategory(category: Category, type?: string) {
    setEditedCategory(category);
    category.superCategory && setParentCategory(category.superCategory);
    setModalCategoryOpen(true);
  }

  function connectCategory(category: Category) {
    setConnectedCategory(category);
  }

  function downloadCategory(category: Category) {
    downloadCategoryMedias(category.id);
  }

  function createSubCategory(parentCategory: Category) {
    setParentCategory(parentCategory);
    setModalCategoryOpen(true);
  }

  function deleteCategory(category: Category) {
    setCategoryToDelete(category);
  }

  function confirmCategoryDelete() {
    if (categoryToDelete !== undefined) {
      deleteCategoryRequest(categoryToDelete.id);
      setCategoryToDelete(undefined);
    }
  }

  function getCategoryById(id?: number) {
    return categories?.find((cat) => cat.id === id) || undefined;
  }

  useEffect(() => {
    if (selectedCategory) {
      window.history.replaceState(null, "", "/folder/" + selectedCategory.id);
      document.title =
        APP_TITLE + " - " + (selectedCategory.superCategory ? selectedCategory.superCategory.label + " > " : "") + selectedCategory.label;
    } else {
      window.history.replaceState(null, "", "/folders");
      document.title = APP_TITLE;
    }
  }, [selectedCategory]);

  useEffect(() => {
    window.addEventListener("scroll", eventHandler);

    function eventHandler() {
      if (document.documentElement.scrollHeight - document.documentElement.scrollTop <= window.innerHeight + 300) {
        if (hasNextPage && !isFetching) {
          fetchNextPage();
        }
        if (hasNextPage2 && !isFetching2) {
          fetchNextPage2();
        }
      }
    }

    return () => {
      window.removeEventListener("scroll", eventHandler);
    };
  }, [fetchNextPage, hasNextPage, isFetching, fetchNextPage2, hasNextPage2, isFetching2]);

  useEffect(() => {
    refetchMedias();
  }, []);

  function closeCategoryModal() {
    setModalCategoryOpen(false);
  }

  function openCategoryModal() {
    setModalCategoryOpen(true);
  }

  function chooseNoFolder() {
    setSelectedCategory(undefined);
    setMedias([]);
    setMediasTotalLength(0);
  }

  function closeMediaModal() {
    setModalMediaOpen(false);
  }

  function openMediaModal() {
    setModalMediaOpen(true);
  }

  function toggleSliders() {
    setIsSlidersOpen((b) => !b);
  }

  useEffect(() => {
    document.addEventListener(OnboradingEvents.CLOSE_FOLDER_MODAL, closeCategoryModal);
    document.addEventListener(OnboradingEvents.OPEN_FOLDER_MODAL, openCategoryModal);
    document.addEventListener(OnboradingEvents.CHOOSE_NO_FOLDER, chooseNoFolder);
    document.addEventListener(OnboradingEvents.CLOSE_MEDIA_MODAL, closeMediaModal);
    document.addEventListener(OnboradingEvents.OPEN_MEDIA_MODAL, openMediaModal);

    return () => {
      document.removeEventListener(OnboradingEvents.CLOSE_FOLDER_MODAL, closeCategoryModal);
      document.removeEventListener(OnboradingEvents.OPEN_FOLDER_MODAL, openCategoryModal);
      document.removeEventListener(OnboradingEvents.CHOOSE_NO_FOLDER, chooseNoFolder);
      document.removeEventListener(OnboradingEvents.CLOSE_MEDIA_MODAL, closeMediaModal);
      document.removeEventListener(OnboradingEvents.OPEN_MEDIA_MODAL, openMediaModal);
    };
  }, []);

  const formatOptionFolder = (data: CategoryOption, formatOptionLabelMeta: FormatOptionLabelMeta<CategoryOption>) => {
    return (
      <div
        className={classNames(style.option, style.folderOption)}
        style={formatOptionLabelMeta.context === "menu" && data.isSub ? { marginLeft: "2rem", border: "none" } : {}}
      >
        <FontAwesomeIcon className={style.folderIcon} icon={faFolder} />
        <span className={style.formatOptionFolder}>{data.label}</span>
      </div>
    );
  };

  const MultiFolderContainer = (props: MultiValueGenericProps<CategoryOption>) => {
    return (
      <div className={style.multiValueContainer} style={{ backgroundColor: "var(--button-hover)" }}>
        {props.children}
      </div>
    );
  };

  const formatOptionUser = (data: UserOption, formatOptionLabelMeta: FormatOptionLabelMeta<UserOption>) => {
    return (
      <div className={style.option}>
        <FontAwesomeIcon className={style.userIcon} icon={faUser} />
        <span style={{ marginLeft: "0.5rem" }}>{data.label}</span>
      </div>
    );
  };

  const MultiUserContainer = (props: MultiValueGenericProps<UserOption>) => {
    return (
      <div className={style.multiValueContainer} style={{ backgroundColor: props.data.color }}>
        {props.children}
      </div>
    );
  };

  return (
    <>
      <Loader
        className={style.isLoading}
        isLoading={isLoaderLoading}
        childrenContainerStyle={{
          position: "absolute",
          width: "20%",
          height: "20%",
          backgroundImage: `url('${MY_DOMAIN}/${loadingIconName}.svg')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></Loader>
      <div
        style={{
          display: "flex",
          margin: "auto",
          maxWidth: "93.75rem",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <Winylo.Card id="right-folder-list" style={{ margin: "1.25rem", maxHeight: "52.8125rem", overflowY: "auto" }}>
            <p
              onClick={() => {
                setSelectedCategory(undefined);
                setMedias([]);
                setMediasTotalLength(0);
                refetchMedias();
              }}
              className={style.allFolder}
            >
              Tous les dossiers
            </p>
            <FolderList
              categories={categories}
              editCategory={editCategory}
              connectCategory={connectCategory}
              downloadCategory={downloadCategory}
              createSubCategory={createSubCategory}
              deleteCategory={deleteCategory}
              selectedCategory={selectedCategory}
              setSelectedCategory={changeSelectedCategory}
              setModalCategoryOpen={setModalCategoryOpen}
              paddingBottom={"7.75rem"}
            />
            {(user?.roles.includes(ROLES.ROLE_ADMIN) || user?.roles.includes(ROLES.ROLE_APPLICATION_ADMIN)) && (
              <Winylo.Button id="create-folder" variant="yellow" fullWidth className={style.btnAddFolder} onClick={() => setModalCategoryOpen(true)}>
                Ajouter un nouveau dossier
              </Winylo.Button>
            )}
          </Winylo.Card>
          <Winylo.Card style={{ margin: "1.25rem" }}>
            <p style={{ color: "var(--blue)", fontWeight: 600 }}>Espace de stockage</p>
            <div style={{ marginBottom: ".25rem" }}>{stringToSize(storageData?.used_storage || 0)}</div>
            <Winylo.ProgressBar progress={((storageData?.used_storage || 0) * 100) / (storageData?.available_storage || 0)} />
          </Winylo.Card>
        </div>
        <div style={{ flex: 3 }}>
          <div className={style.search}>
            <Winylo.Input
              autoComplete="off"
              placeholder="Rechercher"
              style={{ paddingLeft: ".625rem" }}
              inverted
              inputContainerProps={{ className: style.searchInput }}
              background="white"
              value={searchValue}
              onClear={() => setSearchValue("")}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <SlidersIcon className={style.slidersIcon} onClick={() => toggleSliders()} />

            {isSlidersOpen && (
              <PopOver className={style.slidersPopOver} clickOutside={() => {}} hideUglyStyle>
                <Winylo.Card className={style.slidersContainer}>
                  <div className={style.slidersOption}>
                    <div>Type</div>
                    <StyledSelect
                      options={searchOptions}
                      className={style.searchSelect}
                      defaultValue={searchOptions.find((v) => v.value === searchType)}
                      isSearchable
                      onChange={(value: any) => setSearchType(value!.value)}
                    />
                  </div>

                  <div className={style.slidersOption}>
                    <div>Auteur(s)</div>
                    <StyledSelect
                      placeholder="Selectionner..."
                      options={
                        companyUsers
                          ? [
                              ...(companyUsers?.map((u) => {
                                return {
                                  value: u.id,
                                  label: `${u.firstname} ${u.lastname}`,
                                  color: UserOptionColors[Math.floor(Math.random() * UserOptionColors.length)],
                                };
                              }) || []),
                            ].flat()
                          : []
                      }
                      className={style.searchSelect}
                      defaultValue={selectedUsersFilter || []}
                      isSearchable={true}
                      onChange={(value) => {
                        setSelectedUsersFilter(value as UserOption[]);
                      }}
                      isMulti
                      formatOptionLabel={formatOptionUser as any}
                      components={{ MultiValueContainer: MultiUserContainer } as any}
                    />
                  </div>
                  <div className={style.slidersOption}>
                    <div>Dossier(s)</div>
                    <StyledSelect
                      placeholder="Selectionner..."
                      closeMenuOnSelect={false}
                      options={[
                        ...(categories?.map((c) => {
                          let temp = [{ value: c.id, label: c.label } as CategoryOption];

                          temp = [
                            ...temp,
                            ...(c.subCategories?.map((sc) => {
                              return { value: sc.id, label: `${sc.label}`, isSub: true } as CategoryOption;
                            }) || []),
                          ];

                          return temp;
                        }) || []),
                      ].flat()}
                      defaultValue={selectedCategoriesFilter || []}
                      onChange={(value) => {
                        setSelectedCategoriesFilter(value as CategoryOption[]);
                      }}
                      isMulti
                      formatOptionLabel={formatOptionFolder as any}
                      components={{ MultiValueContainer: MultiFolderContainer } as any}
                    />
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Winylo.Button onClick={() => setIsSlidersOpen(false)}>Fermer</Winylo.Button>
                  </div>
                </Winylo.Card>
              </PopOver>
            )}

            <FilterIcon className={style.filterIcon} />
            <Select
              options={filterOptions}
              className={style.searchSelect}
              defaultValue={filterOptions[0]}
              isSearchable={false}
              onChange={(value: any) => setFilterType(value!.value)}
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  boxShadow: "none",
                  borderRadius: 0,
                  cursor: "pointer",
                }),
                option: (provided) => ({
                  ...provided,
                  padding: "8px 6px",
                }),
              }}
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
            />
          </div>
          <Loader
            className={style.isLoading}
            isLoading={isFetching || isFetching2 || loaderState}
            childrenContainerStyle={{
              position: "absolute",
              width: "20%",
              height: "20%",
            }}
          />

          <Winylo.Card style={{ margin: "1.25rem" }}>
            <div className={style.cardTitle}>
              {searchType === SearchType.FOLDER || searchValue.length === 0 ? (
                <Winylo.Breadcrumb>
                  <Winylo.Breadcrumb.Item
                    onClick={() => {
                      setSelectedCategory(undefined);
                      setMedias([]);
                      setMediasTotalLength(0);
                      refetchMedias();
                    }}
                    active={!selectedCategory}
                  >
                    Dossiers
                  </Winylo.Breadcrumb.Item>
                  {selectedCategory?.superCategory !== null ? (
                    <Winylo.Breadcrumb.Item onClick={() => changeSelectedCategory(getCategoryById(selectedCategory?.superCategory?.id))}>
                      {selectedCategory?.superCategory?.label}
                    </Winylo.Breadcrumb.Item>
                  ) : (
                    <></>
                  )}
                  {selectedCategory ? <Winylo.Breadcrumb.Item active>{selectedCategory.label}</Winylo.Breadcrumb.Item> : <></>}
                </Winylo.Breadcrumb>
              ) : (
                <div></div>
              )}
              {hasUserAccessCategory(user, selectedCategory, UserCategoryLevel.LEVEL_ADD) && (
                <Winylo.Button
                  id="button-add-media"
                  format="square"
                  onClick={() => setModalMediaOpen(true)}
                  disabled={selectedCategory ? false : true}
                >
                  Ajouter des médias
                </Winylo.Button>
              )}
            </div>
            <Winylo.Separator className={style.separator} />
            <FolderView
              categories={searchType === SearchType.MEDIA && searchValue.length > 0 ? undefined : filteredCategories}
              category={searchValue.length > 0 ? undefined : selectedCategory}
              setSelectedCategory={changeSelectedCategory}
              searchValue={searchValue}
              setModalMediaOpen={setModalMediaOpen}
              createSubCategory={createSubCategory}
              setModalCategoryOpen={setModalCategoryOpen}
              searchInput={searchInput}
              filter={filterType}
              selectedUsersFilterInput={selectedUsersFilterInput}
              selectedCategoriesFilterInput={selectedCategoriesFilterInput}
              setIsLoaderLoading={setIsLoaderLoading}
              setLoadingIconName={setLoadingIconName}
              mediasTotalLength={mediasTotalLength}
              setMediasTotalLength={setMediasTotalLength}
            />
          </Winylo.Card>
        </div>

        <CategoryModal
          open={modalCategoryOpen}
          onClose={() => {
            setModalCategoryOpen(false);
            setEditedCategory(undefined);
            setParentCategory(undefined);
            queryClient.removeQueries("user_categories");
          }}
          category={editedCategory}
          parentCategory={parentCategory}
          setLoaderState={setLoaderState}
        />

        {selectedCategory && (
          <MediaModal
            open={modalMediaOpen}
            onClose={() => {
              setModalMediaOpen(false);
            }}
            category={selectedCategory}
            searchInput={searchInput}
            filter={filterType}
            selectedUsersFilterInput={selectedUsersFilterInput}
            selectedCategoriesFilterInput={selectedCategoriesFilterInput}
          />
        )}
        {connectedCategory && (
          <ConnectFolderModal
            open={!!connectedCategory}
            onClose={() => setConnectedCategory(undefined)}
            category={connectedCategory}
            setLoaderState={setLoaderState}
          />
        )}
        <Winylo.Modal title="Supprimer le dossier" isOpen={!!categoryToDelete} onClose={() => setCategoryToDelete(undefined)}>
          <p>
            Voulez-vous vraiment supprimer le dossier <span style={{ fontWeight: 600 }}>{categoryToDelete?.label}</span> ainsi que son contenu ?
          </p>
          <div className={style.modalDeleteButtons}>
            <Winylo.Button variant="gray" fullWidth onClick={() => setCategoryToDelete(undefined)}>
              Annuler
            </Winylo.Button>
            <Winylo.Button variant="red" fullWidth onClick={confirmCategoryDelete}>
              Supprimer
            </Winylo.Button>
          </div>
        </Winylo.Modal>
      </div>
    </>
  );
}

export default MainView;
