import { ReactElement, useState } from "react";
import Winylo from "@winylo/winylo-react-component";
import { Category, ROLES, UserCategoryLevel } from "../../api/_type";
import ModalExtanded from "../../winylo/components/ModalExtanded";
import Tabs from "../Tabs/Tabs";
import GeneralForm from "./GeneralForm";
import CategoryAccess from "./CategoryAccess";

import style from "./CategoryModal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { useMutation, useQueryClient } from "react-query";
import api from "../../api/api";
import { UserContextType, useUser } from "../../context/UserContext";
import { hasUserAccessCategory } from "../../utils/utils";

interface Props {
  open: boolean;
  onClose: () => void;
  category?: Category;
  parentCategory?: Category;
  setLoaderState: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function CategoryModal(props: Props) {
  const [user] = useUser() as UserContextType;
  const queryClient = useQueryClient();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isAlertPurgeModalOpen, setIsAlertPurgeModalOpen] = useState<boolean>(false);

  const textToConfirm = "Supprimer";
  const [confirmPurgeInput, setConfirmPurgeInput] = useState<string>("");

  const generalForm = {
    title: "Général",
    renderFunction: (open: boolean, onClose: () => void, category?: Category, parentCategory?: Category) => (
      <GeneralForm category={category} parentCategory={parentCategory} open={open} onClose={onClose} />
    ),
    key: "generalForm",
  };
  const categoryAccess = {
    title: "Accès et notifications",
    renderFunction: (category?: Category) => <CategoryAccess category={category} />,
    key: "access",
  };

  const { mutate: purgeCategory, isLoading } = useMutation(api.categories.purgeCategory, {
    onMutate: () => {
      props.setLoaderState(true);
    },
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries();

      setIsAlertPurgeModalOpen(false);
      setConfirmPurgeInput("");
      props.setLoaderState(false);
    },
    onError: () => {
      props.setLoaderState(false);
    },
  });

  function handlePurgeCategory() {
    props.category && purgeCategory(props.category.id);
  }

  function handleThePurgeButton() {
    setIsAlertPurgeModalOpen(true);
  }

  function returnTitle(): ReactElement {
    return (
      <>
        {props.category !== undefined && hasUserAccessCategory(user, props.category, UserCategoryLevel.LEVEL_EDIT) && (
          <Winylo.Button
            onClick={handleThePurgeButton}
            format="square"
            size="small"
            variant="red"
            type="reset"
            className={style.thePurgeButton}
            id="create-folder-in-modal"
          >
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <span style={{ marginRight: "0.25rem", marginLeft: "0.25rem" }}>Purger</span>
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </Winylo.Button>
        )}
        {props.category === undefined ? (
          props.parentCategory === undefined ? (
            "Créer un dossier"
          ) : (
            <>
              {"Créer un sous-dossier : "}
              <span style={{ color: "var(--black)" }}>{props.parentCategory.label}</span>
            </>
          )
        ) : (
          <>
            {"Modifier le dossier : "}
            {props.parentCategory !== undefined && (
              <>
                <span style={{ color: "var(--black)" }}>{props.parentCategory.label}</span>
                <FontAwesomeIcon icon={faCaretRight} className={style.titleCaret} />
              </>
            )}
            <span style={{ color: "var(--black)" }}>{props.category?.label}</span>
          </>
        )}
      </>
    );
  }

  return (
    <>
      <ModalExtanded
        isOpen={props.open}
        onClose={props.onClose}
        title={<>{returnTitle()}</>}
        modalStyle={{
          content: {
            minHeight: "40.7486rem",
            width: "45vw",
            maxWidth: "93.75rem",
            overflowY: "initial",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        }}
      >
        <Tabs
          selectedItem={selectedTab}
          updateSelectedItem={(index) => setSelectedTab(index)}
          items={props.category ? [generalForm, categoryAccess] : [generalForm]}
          itemsDependencies={{
            generalForm: [props.open, props.onClose, props.category, props.parentCategory],
            access: [props.category],
          }}
        />
      </ModalExtanded>

      <ModalExtanded
        title={<span style={{ color: "var(--red)" }}>Attention !</span>}
        isOpen={isAlertPurgeModalOpen}
        onClose={() => setIsAlertPurgeModalOpen(false)}
        modalStyle={{
          content: {
            width: "25vw",
            maxWidth: "50rem",
            overflowY: "initial",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          },
        }}
      >
        <p>Etes-vous sûr de vouloir purger le dossier {props.category?.label} ?</p>
        <p>
          Cela va supprimer <span style={{ fontWeight: "700", textDecoration: "underline" }}>toutes les photos et toutes les vidéos</span> du dossier.
        </p>
        <p style={{ color: "var(--red)", fontWeight: "700", textAlign: "center" }}>Cette action est irréversible !</p>
        <p>
          Pour confirmer l'action, veuillez écrire "<span className="noselect" style={{ fontWeight: "700" }}>{textToConfirm}</span>" et appuyer sur le bouton{" "}
          <span style={{ color: "var(--red)", fontWeight: "700" }}>Valider</span>.
        </p>
        <Winylo.Input value={confirmPurgeInput} onChange={(e) => setConfirmPurgeInput(e.target.value)}></Winylo.Input>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Winylo.Button disabled={isLoading || confirmPurgeInput !== textToConfirm} onClick={handlePurgeCategory} format="square" variant="red">
            Valider
          </Winylo.Button>
          <Winylo.Button disabled={isLoading} onClick={() => setIsAlertPurgeModalOpen(false)} format="square" variant="blue">
            Annuler
          </Winylo.Button>
        </div>
      </ModalExtanded>
    </>
  );
}
