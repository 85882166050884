import Winylo from "@winylo/winylo-react-component";
import classNames from "classnames";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import Select from "react-select";
import * as Yup from "yup";
import api from "../../api/api";
import { Category, MediaV2 } from "../../api/_type";
import { copyStringToClipboard, getBase64, toFileArray } from "../../utils/utils";
import Carousel from "../Carousel/Carousel";

import style from "./ShareMediaModal.module.css";

interface Props {
  open: boolean;
  onClose: () => void;
  media: MediaV2;
}

export default function ShareMediaModal(props: Props) {
  const [media, setMedia] = useState<MediaV2>(props.media);
  const queryClient = useQueryClient();

  const { mutate: updateMedia, isLoading: isUpdating } = useMutation(api.medias.updateMedia, {
    onSuccess: (result) => {
      queryClient.invalidateQueries("medias");
      queryClient.invalidateQueries("filtered_medias");
      setMedia(result);
    },
  });

  function shareMedia(isPublic: boolean) {
    updateMedia({ id: media.id, isPublic });
  }

  function onClose() {
    if (props.onClose !== undefined) props.onClose();
  }

  function copyLink() {
    copyStringToClipboard(media.uri_public || "");
  }

  return (
    <Winylo.Modal isOpen={props.open} onClose={onClose} title={"Partager le média"}>
      <div style={{ textAlign: "justify" }}>
        <p>En partageant le média, toutes les personnes possédant le lien de partage auront accès au média.</p>
        <div style={{ textAlign: "center" }}>
          <Winylo.Button onClick={() => shareMedia(!media.isPublic)} disabled={isUpdating}>
            {media.isPublic ? "Ne plus partager" : "Partager"}
          </Winylo.Button>
        </div>
        <div className={style.copyLink}>
          <Winylo.Input
            inputContainerProps={{ className: style.input }}
            value={media.uri_public || ""}
            placeholder={"Le média n'est pas partagé"}
            icon={() => <svg></svg>}
          />
          <Winylo.Button variant="yellow" format="square" size="small" className={style.btnCopyLink} onClick={copyLink}>
            Copier
          </Winylo.Button>
        </div>
        <div style={{ textAlign: "center" }}>
          <Winylo.Button style={{ marginTop: "1.875rem" }} onClick={onClose}>
            Fermer
          </Winylo.Button>
        </div>
      </div>
    </Winylo.Modal>
  );
}
